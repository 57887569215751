.mainContainer {

    // display: flex !important;
    // align-items: center !important;
    // justify-content: center !important;
    // flex-direction: column !important;
    .doctorImg {
        padding: 15px;
        height: 120px;
        border-radius: 10px;
    }

    .card {

        display: flex;
        margin: 20px 0;
        // width: 50% !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        p {
            padding-top: 15px;
            font-weight: bold;
            margin: 0 !important;
        }
    }

    .rating {
        font-size: 15px;
        width: 200px;
    }
}

.dateInfoDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;

    p {
        padding: 0;
        margin: 0;
    }
}

.contentDiv {
    width: 100%;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date {
    font-weight: bold;
}

.footer {
    width: 100vw !important;
}

.paymentlist {
    display: flex;

    label {
        padding: 0px 30px 15px 30px;
    }
}

.lineBottom {
    h4 {
        border-bottom: 1px solid #f0f0f0;

    }
}

.divLine {
    width: 100% !important;
    border-bottom: 1px solid #f0f0f0 !important;
    margin-bottom: 25px;
}

input[type="text"]:disabled {
    background-color: transparent;
}

input[type="email"]:disabled {
    background-color: transparent;
}

.btnBg1 {
    width: 135px;
    border-radius: 5px;
    margin-top: 0px !important;
    border-color: #ffffff !important;
    border: 1px solid var(--primary-color) !important;
    color:  var(--primary-color) !important;
    height: 35px;
    font-size: 16px;
    padding-top: 3px;
    background-color: #ffffff !important;
}

.btnBg2 {
    width: 135px;
    border-radius: 5px;
    margin-top: 0px !important;
    border-color: #ffffff !important;
    border: 1px solid #ff0000 !important;
    color: #ff0000 !important;
    height: 35px;
    font-size: 16px;
    padding-top: 3px;
    background-color: #ffffff !important;
}


.submitBtn {
    height: 36px !important;
    margin-bottom: 5px;
}

.cancelBtn {
    height: 36px !important;
}



.box {
    /* margin-top: 115px !important; */
    /* margin-top: 40px !important; */
    margin-top: 5px !important;
    /* border-top: 1px solid #c5e5ff; */
    /* border-bottom: 1px solid #c5e5ff; */
    /* background-color: #F9FAFD ; */
    background-color: #134f85 !important;
    height: 80px;
    width: 100%;
    color: white;
    padding-top: 10px;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.h5New {
    color: white !important;
}

.pageInfo {
    /* padding-top: 10px; */
    /* color: #0071DC !important; */
    color: #ffffff !important;
    font-size: 25px;
    font-weight: 700;
    margin: 0;
}

.stepDiv {
    height: 100%;
    width: 100%;
}

.btnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 480px) {
    .div_summary {
        display: flex;
        flex-direction: column;
    }

    .div_form {
        order: 2;
    }

}

@media (min-width: 481px) and (max-width: 767px) {
    .div_summary {
        display: flex;
        flex-direction: column;
    }

    .div_form {
        order: 2;
    }
}

@media (max-width: 900px) {
    .btnContainer {
        .btnBox1 {
            display: flex;

            // display: grid !important;
            // margin-bottom: 10px !important;
            button {
                margin-bottom: 10px;
            }

            .btnBg2 {
                width: 90px !important;
            }

            .btnBg1 {
                width: 100px !important;
            }

            .submitBtn {
                width: 110px !important;
            }
        }

        .btnBox2 {
            display: flex;
            align-items: flex-start;
        }

        button {
            margin-bottom: 10px;
        }

        .btnBg2 {
            width: 90px !important;
        }

        .btnBg1 {
            width: 100px !important;
        }

        .submitBtn {
            width: 110px !important;
        }

    }
}