.item_down{
    padding: 10px 20px !important;
    border-top: 1px solid rgba(0,0,0,0.1) !important;
}
.userNamee{
    font-size: 14px !important;
    margin-bottom: 0px !important;
}
.pspeciality{
    font-size: 13px !important;
}
.mainContaner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .contactInfo{
        display: block;
    }
    .btn1{
        height: 40px;
        margin-top: 8px;
        background-color: white;
        color: green ;
        border-color: green ;
    }
}
.headerLeftMenu{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.box{
    margin-top: 20px;
    background-color: #F9FAFD ;
    height: 70px;
    width: 100%;
    color: white;
    padding-top: 10px;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    p{
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
}
.logoDiv{
    display: flex;
    width: 200px !important;
    padding-left: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    align-items: center;
    
    
}
.image_round{
    width: 35px;
    height : 35px;
    border-radius: 100%;
    object-fit: cover;
}
.userProfile{
    width: 30px;
    height : 30px;
    border-radius: 100%;
    object-fit: cover;
}
.div_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end !important;
    padding-top: 5px;
}

.borderRightSame{
    border-right: 1px solid rgba(0,0,0,0.1);
    margin-left: 8px;
}
.divIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}
.iconNoti{
    width: 20px;
    cursor: pointer;
}
.iconCal{
    font-size: 30px;
}
@media (min-width: 375px) and (max-width: 812px) {
    .btnBookAppointment {
        width: 165px !important;
        height: 40px !important;
    }
    .borderRightSame {
        margin-left: 8px;
    }
    .divIcon {
        padding-left: 10px;
    }
    .image_round {
        margin-top: 2px;
        width: 25px;
        height: 25px;
    }
}

.notificationcard {
    max-height: 80px; /* Adjust the height as needed */
    overflow: hidden;
  }
  
  /* Apply hover effect to the card on mouse hover */
  .notificationcard:hover {
    overflow: visible;
    max-height: none;
  }

  .notificationcard {
    background-color: #f7f7f7; /* Off-white background color */
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
  
  .cardcontent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .carddetails {
    margin: 0;
    font-size: 1.2rem;
  }

  .notificationbel{
    height: 15 ;
    width: 15;
    background-color: "red";
    // position: absolute;
    // margin-top: "15px";
    // margin-left: "15px";
  }

  .cardbody {
    // padding: 0.8rem !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
  }
  