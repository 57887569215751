.div_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

}

.btn_gray {
  background-color: gray !important;
  border: gray !important;
  border-radius: 50px;
  width: 130px !important;
  color: white;
  height: 45px !important;
}

.calendarCustom {
  width: 100% !important;
  height: 35px;
}

.box {
  margin-top: 5px !important;
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.toggebtn {
  position: absolute;
  // left: 180px;
  font-size: 20px;
  left: 30px
}

.toggle {
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.personHeading {
  padding-top: 20px;
  font-weight: 700;
}

.inputbox {
  height: 45px;
  border-radius: 5px;
  font-size: 16px;
  border-color: var(--primary-color) ;
}

.inputLable {
  font-size: 16px;
  padding-left: 5px;
}

.select {
  height: 45px !important;
  width: 100% !important;
  border-radius: 5px !important;
  border-color: var(--primary-color);
}

.addressHeading {
  padding-top: 15px;
  
}

.selectMember {
  // height: 45px !important;
}

.insureHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.container_Dr {
  display: flex;

  // align-items: center;
  // justify-content: space-between;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;

  }
}

.bgImg {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 20px;
}

.spinDiv {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--rpv-default-layout__toolbar-background-color);
  border-radius: 100%;
  background: var(--rpv-core__spinner-border-transparent-color);
}

.div_card_main {
  // width: 75%;
  width: 100%;
  background-color: #A9D3BF;
  border-radius: 10px;
  padding: 20px;
  padding-top: 55px !important;
  height: 220px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
}

.lbl_card {
  color: #36343C !important;
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

.lbl_card_name {
  color: #36343C !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.div_card_below {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.input_card {
  color: #000000;
  padding: 0px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.input_card_without_radius {
  color: #000000;
  padding: 0px;
  padding-left: 10px;
  margin-bottom: 0px;
  border-color: transparent !important;
}

.input_card_vcode {
  color: #000000;
  width: 60px;
  padding: 0px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.div_row_flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

select:focus-visible {
  outline-offset: 0px;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.marginrem{
  margin-right: 20rem !important;
}

.btnBrowse {
  width:fit-content;
  border-radius: 10px !important;
  border-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff !important;
  padding: 0px 30px;
  
}
.h5Text{
  color: #000 !important;
  font-size: 16px;
  font-weight: 500;
}
