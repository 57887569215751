.div_container {
  width: 100%;
  /* height: 100vh; */
  background-color: #f2f4f7 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.main_container {
  width: 100%;
  height: 100vh;
  background-color: var(--light-primary-bg-color);
  padding: 3%;
}
.row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--primary-border-color);
}
.col {
  flex-basis: 50%;
  height: 100%;
}
.imgStyle {
  width: 100%;
  height: 100%;
  background-image: url("../../images/imgs/login_back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

.leftWrapper {
  width: 100%;
  height: 100%;
  padding: 50px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin: 10px 0px; */
}

.div_content {
  /* width: 1000px; */
  width: 100%;
  background-color: white;
  min-height: 800px;
}
.div_image {
  /* width: 1000px; */
  width: 100%;
  background-image: url("../../images/imgs/back2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 55px;
}
.div_round {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  bottom: -60px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.1);
}
.imgFluid {
  width: 70px;
}
.p_name {
  font-size: 20px;
  color: white !important;
  font-weight: bold;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
.p_desc {
  font-size: 15px;
  color: white !important;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
.div_form {
  width: 100%;
  padding: 40px;
  padding-top: 90px !important;
}
.dont-have {
  margin-top: 20px;
}
.copy {
  font-size: 14px;
  margin-top: 5px !important;
  display: block;
}
.dontHave {
  margin-top: 5px;
}

.inputbox {
  margin-top: -10px;
  width: 100% !important;
  border-radius: 5px;
}
.iconEye {
  position: absolute;
  top: 14px;
  right: 11px;
}
.img_logo_dr{
  position: absolute;
  left: 30px;
  top: 30px;
}


@media only screen and (max-width: 900px) {
 
  .main_container{
   width: 100%;
  }
  .row{
  flex-direction: column;
  }
  .imgStyle{
   display: none;
  }
 }
 @media only screen and (max-width: 600px){
 
  .main_container{
   width: 100%;
   height: 100%;
   padding: 2%;
  }
 }
 @media only screen and (max-height: 700px){
  .main_container{
    height: 100%;
    padding: 2%;
   }
   .col:last-child{height: 900px;}
 }