.modal_flex_layout {
    width: 100%;
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
}

.divPayment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px 35px 0;
}

.arrowIcon {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}

.div_flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.div_inner_flex {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.card_selected {
    margin-right: 20px;
    border: 2px solid #1a6ab1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px !important;
    height: 145px !important;
    margin-bottom: 1rem !important;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
    background-color: #fff !important;
    padding: 5px !important;
  }

  .card_static {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px !important;
    height: 145px !important;
    margin-bottom: 1rem !important;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
    background-color: #fff !important;
    border: 5px solid white !important;
    padding: 5px !important;
  }

  .productImg {
    width: 50px;
    margin-bottom: 10px;
  }

  .btn {
    background-color: #1a6ab1 !important;
    border: 1px solid #1a6ab1 !important;
    width: 200px;
    color: white !important;
    height: 45px !important;
  }


  @media only screen and (max-width: 600px) {
    .div_flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  
    .div_inner_flex {
      width: 60%;
      margin-right: 10px;
      margin-left: 10px;
    }
}