

.mainContainer{
    .doctorImg{
        padding: 15px;
        height: 120px;
        border-radius: 10px;
    }
    .card{
        display: flex;
        margin: 20px 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        p{
            padding-top: 15px;
            font-weight: bold;
            margin: 0 !important ;
        }
      }
      .rating{
        font-size: 15px;
        width: 200px;
      }
}
.dateInfoDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;    
    p{
        padding: 0;
        margin: 0;
    }
}
.contentDiv{
    width: 100%;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.date{
    font-weight: bold;
}

.footer{
    width: 100vw !important;
    position: absolute;
    bottom: 0;
}



 