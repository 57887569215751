.titleHeader {
    background: var(--light-primary-bg-color);
    padding: 15px 16px;
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-color);
    margin-left: 20px;
    margin-right: 20px;
    letter-spacing: 0.1px;
    margin-top: 20px;
}
.subtitleHeader {
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
}

.chipBtn {
    margin-right: 15px;
}

.divider {
    border:1px solid lightgrey;
    border-radius: 5px;
    padding: 20px;
}
.padd {
    
    padding: 0px 20px;
}
.tags{
    font-size: 16px;
    margin:0px 15px;
 }
 .bgTags{
     background-color:  var(--light-primary-bg-color);
     color: var(--primary-color);
     padding: 2px 10px;
 }