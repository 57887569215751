.new{
    border-top : 1px solid #e5f1fb;
    border-bottom : 1px solid #e5f1fb;
    /* position: absolute;
    bottom: 0; */
    width: 100%;
}
.img{
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    background-color: aliceblue;
    border-radius: 50px;
}
.descriptions{
    color: aliceblue;
    padding-left: 32px;
    text-align: left;
    padding-top: 10px;
}

.logoDiv{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 460px;
    padding: 8px 0px;
}
.logoDiv2{
    /* display: flex;
    align-items: center;
    justify-content: end; */
    text-align: end;
    width: 460px;
    padding: 8px 0px;
    
}
.discriptions{
    max-width: 300px;
}
h5{
    color: #0071DC;
}
p{
    color: black !important;
}
.footer_label{
    /* color: #0071DC !important; */
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-left: 30px;
    
}
.footer_label2{
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-right: 30px;
}
.linkA{
    color: #000 !important;
}