.customBtn {
  width: 50px;
  border-radius: 10px !important;
  margin-right: 10px;
}

.event {
  margin-top: rem(5);
  margin-bottom: rem(5);
  margin-right: rem(20);
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
}

.steps {
  width: 100% !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  font-size: 12px;
}

.box {
  margin-top: 5px !important;
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.toggebtn {
  position: absolute;
  // left: 255px;
  font-size: 20px;
  left: 30px;
}

.toggle {
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.text {
  text-transform: lowercase;
}

.flex_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_div2 {
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  margin: 10px 20px;
  background-color: #f9f9f9;
}
.subtitleHeaderNew {
  font-size: 18px !important;
}

@media only screen and (device-width: 375px) and (device-height: 812px) {
  .text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1277px) {
  .Sidebar_div {
    padding-right: 10px !important;
  }
}

.textNormal {
  text-transform: lowercase;
  display: block;
}

.textNormal::first-letter {
  display: block;
  text-transform: uppercase !important;
}

.errorMessage {
  color: red;
  margin-bottom: 10px;
  display: block;
}

.btnDocument{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.vitals_title {
  color: black !important;
  font-size: 16px;
  font-weight: 500;
}

.inputSearch {
	height: 35px;
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}
.div_list {
	height: 200px;
	width: 100%;
	display: flex;
  justify-content: center;
	overflow: auto;
	flex-direction: column;
	// position: absolute;
	background-color: white;
	z-index: 999999999;
	box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.1);
}
.filterName {
	margin: 1px 1px 1px 1px;
	// margin-bottom: -15px;
	border-color: white;
	border: 0.1px solid transparent !important;
	text-align: start;
  height: 120px;
}
.div_main_li {
	width: 100%;
	display: flex;
	// flex-direction: row;
	flex-direction: column;
	align-items: flex-start;
	border: 1px solid transparent !important;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #f1f4ff;
	color: grey;
	margin-bottom: -0.5rem;
	justify-content: space-between;
}

.iconClose {
	position: absolute;
	right: 19px;
	font-size: 22px;
}
.Badge {
	font-size: 10px;
	width: 69px;
	display: inline-block;
	padding: 3px;
	position: 'absolute';
	margin-top: -42px;
	margin-left: -4px;
}
.doctorName {
	margin-bottom: 5px !important;
}
.div_row_flex{
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.titleHeader {
  background: var(--light-primary-bg-color);
  padding: 15px 16px;
  font-size: 18px !important;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 0.1px;
  margin-left: 20px;
  margin-right: 20px;
}

.div_mainChip{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}
.chips{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 6px 14px;
  border-radius: 4px;
  background-color: lightgrey !important;
  color: black;
  background-color: lightgrey !important;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
}
.iconCross{
  font-size: 20px;
  color: gray;
  margin-left: 0px !important;
}
.diseasesHeader{
  color: #000;
  font-weight: 700;
  font-size: 17px;
}
.static_card{
  padding: 15px 20px;
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
}
.static_active_card{
  padding: 15px 20px;
  border: 1px solid var(--green);
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}
.textArea{
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}
.greenBtn{
  background-color: var(--green);
  border-color: var(--green);
  border-radius: 10px;
  color: #fff;
}
.greenBtn:hover{
  background-color: var(--green);
  border-color: var(--green);
  border-radius: 10px;
  color: #fff;
}
.greenBtn:focus{
  background-color: var(--green);
  border-color: var(--green);
  border-radius: 10px;
  color: #fff;
}
.borderedBtn{
  border-color: #000;
  border-radius: 10px;
  color: #000;
}
.borderedBtn:hover{
  border-color: #000;
  border-radius: 10px;
  color: #000;
}
.borderedBtn:focus{
  border-color: #000;
  border-radius: 10px;
  color: #000;
}
.greenSwitchBtn{
  background-color: var(--green);
  border-color: var(--green);
  border-radius: 5px;
  color: #fff;
  padding: 5px 15px;
  cursor: pointer;
  z-index: 3;
  transform: scaleX(1.2) scaleY(1);
  
}
.borderedSwitchBtn{
  border :1px solid #000;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  padding: 5px 15px; 
  cursor: pointer;
}
.tabHeader{
  min-height: 48px;
  padding: 15px 7px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #ebebec;
}
.unshareBtn{
  border :1px solid #000;
  border-radius: 5px;
  background-color: #F0F0F0;
  color: #000;
  padding: 5px 15px; 
  cursor: pointer;
}
.unshareBtn:hover{
  border :1px solid #000;
  border-radius: 5px;
  background-color: #F0F0F0;
  color: #000;
  padding: 5px 15px; 
  cursor: pointer;
}
.unshareBtn:focus{
  border :1px solid #000;
  border-radius: 5px;
  background-color: #F0F0F0;
  color: #000;
  padding: 5px 15px; 
  cursor: pointer;
}
.btnBrowse {
  width:fit-content;
  border-radius: 10px !important;
  border-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff !important;
  padding: 0px 30px;
} 
.pText{
  color: #000  !important;
}
.ulText{
  margin-left: 20px;
}
.accordionHeader{
  font-size: 16px;
  font-weight: 700;
}
.redoBtn{
  border-radius: 100%;
}
.redoBtn:hover, .redoBtn:focus{
  border-color: var(--primary-color);
  color: var(--primary-color);
}
