/* .div_container{
  width: 100%;
  height: 100vh;
  background-color: #F2F4F7 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
} */
.main_container {
  width: 100%;
  height: 100vh;
  background-color: var(--light-primary-bg-color);
  padding: 3%;
}
.row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--primary-border-color);
}
.col {
  flex-basis: 50%;
  height: 100%;
}

.imgStyle {
  width: 100%;
  height: 100%;
  background-image: url("../../images/imgs/login_back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}
.inputStyle {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
}
.inputStyle:hover {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
}
.inputStyle:focus {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
  box-shadow: none !important;
}

/* .div_content{
  width: 450px;
  background-color: white;
    height: 750px;
} */
.div_content {
  width: 100%;
  height: 100%;
  border: 1px solid var(--primary-border-color);
  display: flex;
  background-color: white;
}
.div_img {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div_form {
  width: 100%;
  height: 100%;
  padding: 50px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.main_form {
  width: 100%;
  height: 100%;
  padding: 50px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.btnGreen {
  width: 60%;
  min-width: 300px;
  padding: 7px 0px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  background-color: var(--green);
  border-color: var(--green);
}
.forgotLink {
  color: var(--primary-color);
  font-size: 16px;
  display: flex;
  justify-content: end;
  font-weight: 500;
}
.forgotLink:hover{
  color: var(--primary-color) !important;
  
}
/* .div_image{
  width: 450px;
  background-image: url("../../images/imgs/back.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 55px;
} */
.div_round {
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  bottom: -60px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.1);
}
.imgFluid {
  width: 70px;
}
.p_name {
  font-size: 20px;
  color: var(--primary-color) !important;
  font-weight: bold;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
.p_desc {
  font-size: 15px;
  color: white !important;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}

.dontHave {
  margin-top: 30px;
  color: black;
  font-size: 16px;
}
.copy {
  font-size: 16px;
  margin-top: 20px !important;
  display: block;
  color: black;
}

.ant-spin-dot-item {
  background-color: white !important;
}
.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05) !important;
  color: #f2f4f7 !important;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  /* .div_container{
  height: 100% !important;
  padding: 10px;
  margin: 10px;
  padding-top: 0 !important;
  margin-top: 0 !important;
 } */
  .main_container {
    width: 100%;
  }
  .row {
    flex-direction: column;
    }
    .col:last-child{
      display: none;
      padding: 0%;
      height: 0px;
    }
    
  .imgStyle {
    display: none;
  }
}
/* @media only screen and (max-height:700px) {
 
  .main_container{
   padding: 10px;
  }
 
 }
@media only screen and (max-width: 600px){
 
  .main_container{
   width: 100%;
   height: 100%;
   padding: 30px;
  }
 }

 @media only screen and (max-width: 500px) {
 
  .main_container{
   padding: 10px;
  }
 
 }
 @media only screen and (max-width: 400px) {
 
  .main_container{
   padding: 0px;
  }
 
 } */
