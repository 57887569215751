.imgeAllProblem{
  width: 50px;
  height: 50px;
  margin-left: 10px;
}
.ant-radio-checked .ant-radio-inner{
  border-color: #1a6ab1 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #1a6ab1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #1a6ab1 ;
}
.doctor-widget{
  align-items: center !important;
}
.doc-info-right{
  margin-left: 0px !important;
  width: 100% !important;
  flex: 1 !important;
  max-width: 100% !important;
}
h6{
  color: #757575 !important;
  font-size: 13px !important;
  font-weight: normal !important;
  margin-bottom: 8px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: pre-wrap !important;
}
.card_body_problems{
  padding: 1.3rem !important;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}
.card_problems{
  position: relative;
  display: flex;
  align-items: center;
    margin-bottom: 1rem !important;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
    height: 110px;
    background-color: white !important;
}
.div_btn{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-right: 0px !important;
}
.btn{
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}
.cards{
padding-left: 80px;
padding-right: 80px;
}
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 ;
  margin-bottom: 0;
}

.doctor-widget {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
}
.doc-info-left {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
}
.doc-info-right {
  margin-left: auto;
  -ms-flex: 0 0 205px;
  flex: 0 0 205px;
  max-width: 205px;
}
.box{
/* margin-top: 115px !important; */
/* margin-top: 40px !important; */
margin-top: 5px !important;
/* border-top: 1px solid #c5e5ff; */
/* border-bottom: 1px solid #c5e5ff; */
/* background-color: #F9FAFD ; */
background-color: #134f85 !important;
height: 80px;
width: 100%;
color: white;
padding-top: 10px;
padding-left: 25px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
}
.h5New{
color: white !important;
}
.pageInfo{
/* padding-top: 10px; */
/* color: #0071DC !important; */
color: #ffffff !important;
font-size: 25px;
font-weight: 700;
margin: 0;
}

.footer {
position: fixed;
bottom: 0;
width: 100%;
}


@media only screen 
  and (device-width : 375px) 
  and (device-height : 812px){
    .card_problems{
      display: flex;
      align-items:center;
      width: 350px;
      height: 120px;
    }
    
    .cards{
      padding-left: 0%;
      padding-right: 0px !important
    }
    .info{
      width: 130px;
    }
  }
  


  @media (min-width : 768px) and (max-width : 910px){
    .card_problems{
      display: flex;
      align-items:center;
      height: 135px;
    }
    /* .info{
      padding-right: 10px;
    } */
  }

  @media all and (min-width: 768px) and (max-width: 1024px) and (orientation:portrait){
    .card_problems{
      width: 280px;
      display: flex;
      align-items: center;
    }
    .info{
      width: 130px;
    }
  }
  .iconDiv{
    /* position: relative; */
  }
  .iconEye{
    position: absolute;
    width: 20px;
    top: 10px;
    right: 15px;
  }