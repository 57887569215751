.spinner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.container1 {
  height: 100%;
  width: 100%;
  padding-bottom: 150px;
}
.doctorImg {
  width: 150px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 100%;
}
.card3 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
}
.card3_activee {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  background-color: #fff;
}
.card2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  background-color: #fff;
}
.pb20 {
  padding-bottom: 10px !important;
}

.rowAuto {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div_right {
  padding-left: 20px;
  max-width: 300px;
  word-wrap: break-word;
}
.roww {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}
.docName {
  font-size: 18px;
  font-weight: bolder;
  color: rgb(75, 72, 72);
  margin: 0px !important;
}
.addressName {
  font-size: 15px;
  color: gray;
  margin: 0px !important;
  margin-left: 10px !important;
}
.doc_desc {
  font-size: 14px;
  color: #757575;
  margin: 0px !important;
}
.doc_desc_blue {
  font-size: 14px;
  color: #20c0f3;
  margin: 0px !important;
  margin-top: 10px !important;
}
.icon {
  width: 18px;
  height: 18px;
}
.btnDoc {
  border-radius: 10px;
  height: 30px;
  margin-top: 15px;
  margin: 3px 7px;
}
.btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.btnBg2 {
  border-radius: 5px;
  margin-top: 0px;
  border-color: var(--primary-color)!important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  width: 100%;
  /* width: 49%; */
  padding: 0px !important;
  height: 45px;
  font-size: 13px;
}
.btnBg {
  height: 45px;
  width: 100%;
  /* width: 49%; */
  padding: 0px !important;
  border-radius: 5px;
  margin-top: 10px;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: white !important;
  font-size: 13px;
}
.newRIght {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.tagg {
  width: 450px !important;
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
}
.imgFluid {
  object-fit: contain;
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

.div_btn_inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end !important;
  padding-top: 5px;
  padding-right: 0px !important;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.familyDoctor {
  padding: 5px 10px;
  font-size: 15px;
  color: white;
  /* background-color: rgba(19, 79, 133, 0.9); */
  background-color: #6c757d;
  position: absolute;
  top: 0;
  right: 0;
}
.cardsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
.card {
  border-radius: 5px;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0 0 3px rgb(52 58 64 / 15%) !important;
  /* margin: 10px; */
  /* width: 292px; */
  width: 255px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 10px;
}
.cardImg {
  display: flex;
  align-items: center;
  justify-content: center;

  /* position: relative; */
  height: 160px;
}
.imgIcon {
  padding: 8px 5px 3px 10px;
  position: absolute;
  box-shadow: 0 3px 5px 0 rgb(240 115 90 / 30%);
  top: 10px;
  right: 10px;
  color: #f0735a !important;
  background-color: rgba(240, 115, 90, 0.1) !important;
  border-radius: 30px;
}
.cardBody {
  display: flex;
  text-align: center;
  flex-direction: column;
  background-color: white;
  padding: 25px;
  color: var(--primary-color);
}
.cardTitle {
  font-size: 20px;
}
.cardSubTitle {
  font-size: 12px;
}
.cardDetails {
  display: flex;
  flex-direction: row;
}
.cardDetailsIcon {
  color: #396cf0 !important;
}
.stars {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row1 {
  display: flex;
  flex-direction: row;
}
.row2 {
  display: flex;
  flex-direction: row;
}
.search {
  display: flex;
  flex-direction: row;
  align-items: center;
}

input {
  border-radius: 2px;
  border: 1px solid lightgray;
  padding: 10px;
  width: 100%;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}

.searchIcon {
  position: absolute;
  right: 15px;
  bottom: 11px;
}
.cardBody {
  text-align: center;
  box-shadow: 0px 15px 15px -8px rgba(0, 0, 0, 0.2);
}
.wordWrap {
  white-space: nowrap !important;
  /* width: 50px;  */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-bottom: 10px;
}
.ilips{
  overflow: hidden;
  white-space: nowrap;
  /* width: 200px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.div_address{
  /* white-space: nowrap;  */
  /* overflow: hidden; */
  /* text-overflow: ellipsis;  */

  white-space: nowrap !important;
  /* width: 50px;  */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.div_address p{
  margin-bottom: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1395px) and (min-width: 677px) {
  /* h3:after {
        content: ' - min-width 1024px and max-width 1440px large screen-only' !important;
        display: inline;
    } */
  .card {
    /* width: 250px !important; */
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1285px) and (min-width: 677px) {
  /* h3:after {
        content: ' - min-width 1024px and max-width 1440px large screen-only' !important;
        display: inline;
    } */
  .card {
    /* width: 300px !important; */
    margin-left: 0px;
  }
}
@media (min-width: 1200) {
  /* h3:after {
        content: ' - min-width 1024px and max-width 1440px large screen-only' !important;
        display: inline;
    } */
  /* .card{
        margin-bottom: 100px;
    } */
}
/* min-width 1024px and max-width 1440px, use when QAing large screen-only issues */

@media (min-width: 400px) and (max-width: 750px) {
  .card {
    width: 400px !important;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .card {
    width: 400px !important;
  }
}

@media all and (min-width: 750px) and (max-width: 1200px) {
  /* h3:after {
        content: ' - min-width 768 and max-width: 1024px Ipad portrait' !important;
        display: inline;
    } */

  .card {
    width: 280px;
  }
}

@media (min-width: 320px) and (max-width: 800px) {
  /* h3:after {
        content: ' - iphones';
        display: inline;
    } */
  .card {
    width: 350px !important;
  }
}

@media (min-width: 720px) and (max-width: 770px) {
  .card {
    width: 200px !important;
  }
}

@media (min-width: 760px) and (max-width: 850px) {
  .card {
    width: 250px !important;
  }
}

/* 
@media (max-width:740px){
    .card{
        width: 200px !important;
    }
}  */
/* 
@media (max-width:750px){
    .card{
        width: 200px !important;
    }
}  */

@media (max-width: 746px) {
  .card {
    width: 400px !important;
  }
}

@media (min-width: 320px) and (max-width: 600) {
  .card {
    width: 500px !important;
  }
}
