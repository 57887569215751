.h5New {
  font-size: 16px;
  color: #ced4da !important;
}

.breadcrumbDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 7px;
  margin-bottom: 5px;
}
.headerTop {
  background-color: #b476d3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
