.dependent_center {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px !important;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

.container {
  padding-left: 15px;
  padding-right: 15px;
}





.h5New {
  color: white !important;
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.toggebtn {
  position: absolute;
  /* left: 200px; */
  font-size: 20px;
  left: 30px
}

.toggle {
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.btn {
  background-color: var(--primary-color) !important;
  border: 1px solid  var(--primary-color) !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
  font-size: 15px;
}



.div_card_main {
  width: 80%;
  background-color: #A9D3BF;
  border-radius: 10px;
  padding: 20px;
  padding-top: 55px !important;
  height: 220px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.lbl_card {
  color: #36343C !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
}

.lbl_card_name {
  color: #36343C !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
}

.div_card_below {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.div_row_name {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
}

.div_row_flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

input[type="text"]:disabled {
  background-color: white;
}

.input_card {
  color: #000000;
  padding: 0px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.input_card_without_radius {
  color: #000000;
  padding: 0px;
  padding-left: 10px;
  margin-bottom: 0px;
  border-color: transparent !important;
}

.input_card_vcode {
  color: #000000;
  width: 60px;
  padding: 0px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.lblDate{
  font-weight: bold;
  display: block;
  font-size: 15px;
  margin: 0px !important;
  margin-bottom: 5px !important;
  text-transform: capitalize;
}

.lblnormal{
  display: block;
  font-size: 13px;
  margin: 0px !important;
  margin-bottom: 10px !important;
}

.spann{
  font-weight: bold;
  font-size: 13px;
  margin: 0px !important;
  text-transform: capitalize;
}

.spannDate{
  font-weight: bold;
  font-size: 13px;
  margin: 0px !important;
  text-transform: capitalize;
}
.div_flexbox{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div_first{
  width: 40%;
}
.lbl_carconsent{
  font-size: 20px;
  margin-right: 10px;
  margin-top: 20px;
}

.inputStyle {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
  min-height: 40px;
}
.inputStyle:hover {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
}
.inputStyle:focus {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.btnBrowse {
  width:fit-content;
  border-radius: 10px !important;
  border-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff !important;
  padding: 0px 30px;
  
}