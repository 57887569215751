.mainDiv{
  width: 100%;
  height: 100vh;
  background-color: rgb(241, 245, 249);
}
.toggebtn{
  position: absolute;
  font-size: 20px;
  left: 30px;
}
.personHeading {
  padding-top: 20px;
}
.toggle{
  position: absolute;
  z-index: 99;
  min-width: 300px;
}
.divError{
  width: 100%;
  label{
    color: red;
    font-size: 14px;
  }
}
.btnWithAddon {
  overflow: hidden;
  position: relative;
  padding: 5px !important;
  margin: 5px !important;
  border: none;
  width: fit-content;
  font-size: 13px !important;

  .btnAddon {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    background-color: rgba(white, 0.2);
    width: rem(40);
  }

  .btnAddonIcon {
    font-size: rem(16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.todayBox{
  padding: 10px;
}

.todayBox :hover{
  padding: 10px;
  border-radius: 10px;
  background-color: #e6ecff;
  cursor: pointer;
}
.container{
  padding-left: 15px;
  padding-right: 15px;
}
.inputboxContainer{
  margin-top: 10px;
}
.lbl_slash{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 5px;
}
.div_icon_white{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lbl_sa{
  font-size: 12px;
  display: block;
}
.div_flex_column{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.div_flex_row{
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.div_icon_white_new{
  width: 100%;
  height: 120px;
  background-color: white !important;
  color: black !important;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
  margin-right: 10px;
  margin-top: 7px;
  border: 0px !important;
  cursor: pointer;
}
.div_row_lbl{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 7px;
}
.lbl_head{
  font-size: 14px;
  margin: 0px;
  margin-left: 10px;
}
.lbl_head_without_margin{
  font-size: 14px;
  margin: 0px;
  margin-top: 5px !important;
  margin-left: 7px;
}
.lbl_light{
  font-size: 12px;
  margin: 0px;
  margin-top: 5px !important;
}
.div_emoji{
  width : 100%;
  background-color: white !important;
  color: black !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
  margin-right: 10px;
  margin-top: 7px;
  border: 0px !important;

  label{
    margin: 0px !important;
    font-size: 15px;
    margin-top: 10px !important;
  }
}

.div_emoji_selected{
  width : 100%;
  background-color: white !important;
  color: black !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
  margin-right: 10px;
  margin-top: 7px;

  label{
    margin: 0px !important;
    font-size: 15px;
    margin-top: 10px !important;
  }
}
.lbl_error{
  font-size: 14px;
  color: #ff4d4f;
  margin: 0px;
}
.imageInput{
  width: 50px;
}

@media (min-width: 900px){
  .heading{
    padding-left: 20px;
  }
  .ratingDiv{
    padding-left: 20px;
  }
}

@media only screen and (max-width: 770px) {
  .div_btn{
    padding: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .btnWithAddon {
    
  font-weight: bold;
  font-size: 11px !important;
 
  }
}

.divList{
  width: 100%;
  background-color: white;
}
.div_item{
  width: 100%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 15px;
  background-color: white !important;
  color: black !important;
  border: none !important;
  border-bottom: 1px solid #f1f5f9 !important;
  padding: 10px !important;
}
.div_item_selected{
  width: 100%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 15px;
  background-color: rgba(137,88,162,0.3) !important;
  color: black !important;
  border: none !important;
  border-bottom: 1px solid #f1f5f9 !important;
  padding: 10px !important;
}
.div_btns{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.div_Blue{    
  // background-color: rgba(26,106,177,0.3);
  padding: 10px 20px;
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  label{
    font-size: 15px;
    margin: 0px !important;
  }
}
.div_orange{
  // background-color: rgba(255, 157, 92,0.3) !important;
  padding: 10px;
  border: none !important;
  // color: black !important;
  padding: 10px 20px !important;
  width: 180px;

  label{
    font-size: 15px;
    margin: 0px !important;
  }
}
.wrapper{
  // padding-right: 30px;
}