.mainDiv{
  width: 100%;
  height: 100vh;
  background-color: rgb(241, 245, 249);
}
.dependent_center {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px !important;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

.container {
  padding-left: 15px;
  padding-right: 15px;
}
.btn {
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}
.div_row_flex{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.h5New {
  color: white !important;
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}
.buttonJoin {
  background-color: #fafafa;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer !important;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.buttonJoinDisabled {
  background-color: #ccc;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer !important;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.buttonView {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer !important;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 5px 25px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 110px;
}
.btnTransparentNew{
  background-color: transparent !important;
  border-color: transparent !important;
  width: 50px;
}
.iconComment{
  font-size: 23px !important;
  color: #1a6ab1;
}
.toggebtn{
  position: absolute;
  font-size: 20px;
  left: 30px;
}