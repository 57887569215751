.ant-row {
    display:block !important;
}
button {
    border: 0;
    font-weight: 500;
  }
  
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  #form-container {
    position: relative;
    /* width: 380px; */
    /* margin: 0 auto; */
    top: 50%;
  }
  
  .third {
    float: left;
    width: calc((100% - 32px) / 3);
    padding: 0;
    margin: 0 16px 16px 0;
  }
  
  .third:last-of-type {
    margin-right: 0;
  }
  
  /* Define how SqPaymentForm iframes should look */
  .sq-input {
    height: 56px;
    box-sizing: border-box;
    border: 1px solid #E0E2E3;
    background-color: white;
    border-radius: 6px;
    display: inline-block;
    -webkit-transition: border-color .2s ease-in-out;
    -moz-transition: border-color .2s ease-in-out;
    -ms-transition: border-color .2s ease-in-out;
    transition: border-color .2s ease-in-out;
  }
  
  /* Define how SqPaymentForm iframes should look when they have focus */
  .sq-input--focus {
    border: 1px solid #4A90E2;
  }
  
  /* Define how SqPaymentForm iframes should look when they contain invalid values */
  .sq-input--error {
    border: 1px solid #E02F2F;
  }
  
  #sq-card-number {
    margin-bottom: 16px;
  }
  
  /* Customize the "Pay with Credit Card" button */
  .button-credit-card {
    width: 100%;
    height: 56px;
    margin-top: 10px;
    margin-bottom: 20px;
    background: #4A90E2;
    border-radius: 6px;
    cursor: pointer;
    display: block;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    -webkit-transition: background .2s ease-in-out;
    -moz-transition: background .2s ease-in-out;
    -ms-transition: background .2s ease-in-out;
    transition: background .2s ease-in-out;
  }
  
  .button-credit-card:hover {
    background-color: #4281CB;
  }