.modal_flex_layout{
    width: 100%;
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
}
.Info{
    color: #666;
    display: block !important;
    position: relative;
    font-size: 14px !important;
    // font-size: .9375rem;
    padding-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.inputbox {
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    // border: 1px solid var(--primary-border-color)  !important;
  }
  .btnGreen{
    width: 60%;
    min-width: 300px ;
    padding: 7px 0px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    background-color: var(--green);
    border-color:var(--green);
  }

  .inputbox2{
    margin-bottom: 10px;
  }