.headerInfo{
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */
    /* margin: 10px; */
}
.inputCalandar{
    width: -webkit-fill-available;
    border-radius: 5px;
    height: 45px;
    font-size: 19px;
    padding-left: 10px;
}
.searchBtn{
    width: -webkit-fill-available !important;
}
.Info{
    color: #666;
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-size: .9375rem;
    padding-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.datePicker{
    width: -webkit-fill-available;
}