
.div_container{
  width: 100%;
  height: 100vh;
  background-color: #F2F4F7 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.div_content{
  width: 450px;
  background-color: white;
  height: 680px;
}
.div_image{
  width: 450px;
  background-image: url("../../images/imgs/back.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 55px;
}
.div_round{
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  bottom: -60px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgb(0,0,0,0.1);
}
/* .imgFluid{
  width: 70px;
} */
.p_name{
  font-size: 20px;
  color: white !important;
  font-weight: bold;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
.p_desc{
  font-size: 15px;
  color: white !important;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
/* .div_form{
  width:  100%;
  padding: 40px;
  padding-top: 100px !important;
} */
.dontHave{
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
}
.copy{
  font-size: 14px;
  margin-top: 5px !important;
  display: block;
  margin-bottom: 20px;
}

.ant-spin-dot-item {
  background-color: white !important;
}
.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05) !important;
  color: #F2F4F7 !important;
  border-radius: 4px;
}
.iconEye{
  position: absolute;
  top: 14px;
  right: 11px;
}




.main_container {
  width: 100%;
  height: 100vh;
  background-color: var(--light-primary-bg-color);
  padding: 3%;
}
.row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--primary-border-color);
}
.col {
  flex-basis: 50%;
  height: 100%;
}
.imgFluid{
  width: 94px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  background-image: url("../../images/imgs/login_back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}
.inputStyle {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
}
.inputStyle:hover {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
}
.inputStyle:focus {
  border: 1px solid var(--primary-border-color) !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.div_form {
  width: 100%;
  height: 100%;
  /* padding: 50px 50px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
}
.main_form {
  width: 100%;
  padding: 50px 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  flex-direction: column;
}
.formStyle{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.btnGreen {
  width: 60%;
  min-width: 300px;
  padding: 7px 0px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  background-color: var(--green);
  border-color: var(--green);
}


@media only screen and (max-width: 900px) {
  /* .div_container{
  height: 100% !important;
  padding: 10px;
  margin: 10px;
  padding-top: 0 !important;
  margin-top: 0 !important;
 } */
  .main_container {
    width: 100%;
  }
  .row {
    width: 100%;
    flex-direction: column;
    }
    .col:first-child{
      width: 100%;
      height: 100%;
      flex-basis: 100%;
    }
    .col:last-child{
      display: none;
      padding: 0%;
      height: 0px;
      width: 0%;
    }
      .main_form{
        flex-direction: column;
      }
    
  .imgStyle {
    display: none;
  }
}