/* @font-face {
	font-family: Lato;
	src: url(../fonts/lato/Lato-Regular.ttf);
} */
.avatar-img {
  width: 45px;
  border-radius: 50px;
}
.vi_control > button .anticon {
  font-size: 20px !important;
}

/* chat on call start */
.my-action li {
  list-style: none;
  padding: 6px;
  margin: 0 8px;
  max-width: 40px;
  position: relative;
}
.my-action {
  position: fixed;
  z-index: 1;
  padding: 0;
  right: 0;
  border-bottom-left-radius: 8px;
  background: #fff;
}

.cursor-pointer {
  cursor: pointer;
}
.mainvideowrap .row {
  margin: 0px;
}

.overlay {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 999999999;
  top: 0px;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  border-left: 0.5px solid #e6e6e6;
}

.overlay-content {
  width: 100%;
  left: 0;
  right: 0;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  color: #818181;
  margin: auto;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #000;
}

.overlay .closebtn {
  font-weight: 500;
  font-size: 22px;
  cursor: pointer;
  margin-left: 0.75rem;
}

.my-nav-action {
  text-align: center;
  padding: 6px;
  background: #edeef6;
  border-radius: 4px;
}
.my-nav-action li {
  width: 50%;
}
.my-nav-action li .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: white;
  color: #000;
  padding: 10px;
  border-radius: 4px;
}

.people-image img {
  height: 100%;
  width: 100%;
  border-radius: 100px;
}
.people-image {
  position: relative;
  height: 70px;
  width: 100%;
  padding: 10px;
}
.people-image .mute-icon {
  height: 30px;
  width: 30px;
  position: absolute;
  color: #fff;
  border-radius: 100px;
  padding: 10px 0;
  text-align: center;
  bottom: 4px;
  right: 4px;
  font-size: 12px;
}
.participate {
  background: gainsboro;
  border-radius: 6px;
  margin: 4px 0;
}
.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  list-style: none;
}

.flex-item {
  width: 300px;
  height: 200px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
}
.textbox-div {
  position: absolute;
  width: 100%;
  bottom: 0;
  outline: none;
  right: 0;
}

.received_msg {
  display: flex;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 3px 10px 0px 8px;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-radius: 10px;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 9px;
  text-align: right;
}
.controllButtonsForCallNew {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
}
.sent_msg .time_date {
  color: #fff;
}
.custom-row {
  display: flex !important;
  flex-direction: row;
}
.received_withd_msg {
  width: 90%;
  margin: 3px 0;
}
.sent_msg p {
  background: #0176ff none repeat scroll 0 0;
  border-radius: 10px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 0px 10px 0px 6px;
  width: 100%;
  border-bottom-right-radius: 0;

  padding: 5px 10px;
}
.outgoing_msg {
  overflow: hidden;
  margin: 2px auto;
}
.sent_msg {
  float: right;
  width: auto;
}

.mesgs {
  overflow-y: scroll;
  position: absolute;
  width: 95%;
  height: calc(100% - 160px);
}

.msg_history {
  padding-bottom: 30px;
}

.received_msg {
  display: flex;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 3px 10px 0px 8px;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-radius: 10px;
}

.message-text {
  border-radius: 0.625rem 0.625rem 2px 0.625rem;
  padding: 4px 10px;
  font-size: 14px;
  word-break: break-word;
}

.user-from-chat {
  position: relative;
  top: 25px;
}
.user-from-chat .message-text {
  border-radius: 2px 0.625rem 0.625rem 0.625rem;
}
.user-from-chat .bg-light {
  background-color: #f6f6f6 !important;
}
/* chat on call start end */

.callactions {
  margin-right: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.callactions i {
  margin-right: 10px;
}

.sidebarRigntLine {
  border-right: 0.5px solid #e6e6e6;
}

#videoBackground video {
  object-fit: contain;
}
.flip-this-video {
  transform: scaleX(-1);
}
.reciever_video #videoBackground video {
  object-fit: contain;
  /* background: rgb(68, 68, 68) none repeat scroll 0% 0%; */
}

.video-container {
  /* min-height: 400px; */
  top: 0;
  bottom: 0;
  width: 100%;
  /* border-radius: 5px; */
  /* box-shadow: 5px 5px 10px gray; */
  overflow: hidden;
  flex: 1;
}
.no-min {
  min-height: unset !important;
}
.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.self_video {
  position: fixed;
  /* right: 20px; */
  top: 20px;
  width: 250px;
  border-radius: 10px;
  /* height: 250px; */
  background: #ffff;
  overflow: hidden;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.34);
  z-index: 1000;
  min-width: 250px !important;
}
.self_video video {
  width: 100%;
  height: 100%;
}
.bg_dark {
  background: #000;
}
.mainvideowrap {
  align-items: center;
  justify-content: center;
  height: 100%;
}
.localVideoClass {
  position: relative;
}
.videoCallFullWidth .vi_control {
  height: 60px;
  max-width: max-content;
  min-width: fit-content;
}
.vi_control {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  /* height: 60px; */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.P_side_vi_control {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #7d7d7da8;
  padding: 8px;
  width: max-content;
  margin: 0 auto;
  opacity: 0.2;
  pointer-events: fill;
  box-sizing: content-box;
  border-radius: 10px 10px 0px 0px;
}
.P_side_vi_control:hover {
  opacity: 1;
}
.actn_btn {
  border: 1px solid #f1f1f4;
  background-color: #f1f1f4;
  color: #656565;
  line-height: 45px;
  height: 45px;
  width: 45px;
  margin: 0px 5px;
  border-radius: 50%;
}
.actn_btn.edncall {
  background-color: #da3911;
  color: #fff;
  border-color: #da3911;
}
.actn_btn.edncall:hover {
  background-color: #be2f0b;
  color: #fff;
  border-color: #be2f0b;
}
.actn_btn i.icon-lg {
  font-size: 20px !important;
  margin-right: 0px;
}
.actn_btn:hover {
  cursor: pointer;
}

.inCommingCall {
  background-color: #333;
  height: 75px;
  width: 100%;
  z-index: 999999;
  position: fixed;
  top: 0;
}
.icomingcalls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
}
.callactions {
  margin-right: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.callactions i {
  margin-right: 10px;
}
/* .icomingcalls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
} */

.sidebarRigntLine {
  border-right: 0.5px solid #e6e6e6;
}

.mediaContainer {
  position: absolute;
  /* margin-top: 15px; */
  margin-left: 15px;
}

.fullscreen100 {
  object-fit: cover;
  height: 100% !important;
  width: 100% !important;
}

.localVideoClassVideo {
  -webkit-transform: scaleX(-1);

  height: 100px;
}
.loader1 {
  /* border: 20px solid white; */
  /* border-right-color: greenyellow; */
  /* border-color: rgb(219, 214, 214);
  border-top-color: steelblue; */
  /* border-left-color: teal; */
  /* height: 100%; */
  width: 164px;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  /* background-image: url(''); */
}
.loaderHome {
  height: 100%;
  width: 100%;
}

.callingContainerMeeting {
  position: absolute;
  /* top: 50%; */
  /* left: 68%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* margin-left: -44%; */
  /* margin-top: -25%; */
}
.small-text-container {
  width: 100px;
  max-width: 100px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  max-height: 50px;
  /* white-space: nowrap; */
}

.small-text-container-custom {
  width: 100px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 50px;
  white-space: nowrap;
}

.received_msg {
  display: flex;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 3px 10px 0px 8px;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-radius: 10px;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 9px;
  text-align: right;
}

.audio-circle {
  z-index: 999999999999999;
  border: 10px dashed pink;
  visibility: hidden;
  align-self: center;
  height: 100px;
  width: 300px;
  position: absolute;
  left: 45%;
  transform: translateX(-55%);
  top: 10%;
  border-radius: 50%;
  animation: spinnew 2s ease-in-out infinite;
}
.initials-box {
  /* background: yellow; */
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}
.circle {
  border-radius: 50%;
  /* background-color: rgb(58 144 186); */
  height: 111px;
  width: 111px;
  position: absolute;
  opacity: 0.5;
  z-index: 21;
  transition: 0.3s all;
  filter: invert(1);
  /* animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32); */
}
.circle-2 {
  border-radius: 50%;
  border: 4px solid rgb(37, 64, 184);
  height: 121px;
  width: 121px;
  position: absolute;
  opacity: 0.5;
  z-index: 21;
  transition: 0.2s all;
  filter: invert(1);
  /* animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32); */
}

.circle-3 {
  border-radius: 50%;
  border: 1px solid rgb(29, 111, 117);
  height: 121px;
  width: 121px;
  position: absolute;
  opacity: 0.5;
  z-index: 21;
  transition: 0.3s all;
  filter: invert(1);
  /* animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32); */
}
.initials {
  z-index: 22;
  font-size: 64px;
  text-transform: uppercase;
  font-weight: bolder;
  /* background: white; */
  /* border:1px solid grey; */
  height: 111px;
  width: 111px;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.hand-container {
  position: absolute;
  top: 10px;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  background-color: rgb(236, 233, 233);
  padding-left: 5px;
}
.hand-container div {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateZ(-36deg);
  animation: bubble 1s ease-out infinite;
}

.hand-container span {
  font-size: 12px;
  margin-left: 5px;
}

.mic-indication {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.version {
  position: fixed;
  top: 0px;
  background: white;
  height: 40px;
  width: 15%;
  border-radius: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 50vw;
  z-index: 99900000000000000;
  transform: translateX(-50%);
  color: teal;
  border: 1px solid teal;
  padding: 5px;
  padding-left: 20px;
}
.resync {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  color: white;
  background-color: tomato;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
  transition: all 0.3s;
}
.resync:hover {
  background: white;
  color: tomato;
  /* box-shadow: inset 2px 2px 2px tomato; */
  border: 1px solid tomato;
}
.resync:active {
  background: white;
  color: tomato;
  transform: rotateZ(1360deg);
  box-shadow: inset 2px 2px 2px tomato, inset -2px -2px 2px rgb(241, 235, 234);
  border: 1px solid tomato;
}
#local_video {
  transition: all 0.5s;
  background-color: rgb(143, 146, 172);
  height: 100%;
  transform: scaleX(-1);
}
#local_video:hover {
  position: absolute;
  top: 0;
  right: 0;
  height: 250px;
  /* transform:scale(3);  */
  /* display: block; */
}

.panel {
  width: 20%;
  height: 100px;
  position: absolute;
  left: 40%;
  background-color: white;
  /* box-shadow: 0px 10px 10px grey; */
  border-radius: 30px;
  transform: rotateX(60deg) skew(60deg);
}
.bgclassdoc {
  padding: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 10px;
  /* width: 300px!important; */
  /* display: flex!important;
    justify-content: center!important;
    align-items: center!important; */
  /* height: 80px; */
  background-color: rgb(19, 18, 18);
}
.classdoc {
  /* display: none; */
  /* display: flex!important; */
  /* justify-content: center!important; */
  /* align-items: center!important; */
  border-radius: 10px;
  /* background-color: red; */
  opacity: 0.3;
  position: fixed;
  bottom: 10px;
  transition: all 0.3s;
}
.mobile-control:hover {
  opacity: 1;
}

.local .initials-box {
  width: 60px !important;
  background-color: black !important;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.reminder-fields label.primary-active {
  background-color: #246ec6;
  color: #fff !important;
}
.reminder-fields input[type="checkbox"] {
  display: none;
}
.vi_control .actn_btn {
  background-color: #fff !important;
  height: 35px !important;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 6px;
  border-radius: 50% !important;
  color: #000 !important;
  text-align: center;
  /* margin: 0px 0px 0px 0px; */
}
.videoCallFullWidth .vi_control .actn_btn {
  height: 45px !important;
  width: 45px;
  margin: 0px 8px;
}
.videoCallFullWidth .fephone {
  height: 45px !important;
  width: 45px;
  margin: 0px 8px;
}
.vi_control .fephone {
  background-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  box-sizing: border-box;
  transform: rotate(225deg) !important;
}
.fa-rotate-45 {
  -webkit-transform: rotate(137deg);
  -moz-transform: rotate(137deg);
  -ms-transform: rotate(137deg);
  -o-transform: rotate(137deg);
  transform: rotate(137deg);
}
.call_icon_Color {
  color: #dc3545;
}
.fadesktop {
  line-height: inherit !important;
}
.vi_control .actn_btn i.icon-lg {
  font-size: 16px;
  margin-right: 0px;
  position: relative;
  bottom: 3px;
  padding: 13px;
}
.vi_control .actn_btn.edncall {
  background-color: transparent !important;
  border: none;
}
.vi_control .actn_btn:focus {
  outline: none !important;
}
.video-container video {
  height: 100% !important;
  /* border-radius: 20px;
  box-shadow: 3px 3px 5px #d4d4d4, -2px -2px 5px #d4d4d4; */
}
.video-container .callingContainerMeeting {
  /* width: auto !important; */
  /* left: 50%; */
  z-index: 10;
  background-color: transparent;
}

#listOfAppoitnment .colorfff {
  background-color: #ffffff;
  padding: 15px;
  min-height: 200px;
}

.line_height_auto {
  line-height: unset !important;
}
.line_height41 {
  line-height: 41px !important;
}
.footer {
  position: relative;
  left: 11px;
  right: 0px;
  bottom: 0;
  padding-right: 1rem;
  border-left: 1px solid #fff;
}

.videoCallFullWidth {
  /* border: dashed 1px #CCC; */
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

#divResize {
  /* border: dashed 1px #CCC; */
  width: 500px;
  height: 420px;
  margin: 5px;
  cursor: move;
  float: left;
  right: 0;
  z-index: 3000;
  position: fixed !important;
  top: 0;
  min-width: 30%;
  background-color: #000;
  min-height: 32%;
}
.adminVideoScreen {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 50px;
  top: 10px;
  max-width: 0;
  z-index: 999;
  min-width: 17% !important;
}
.fullscreeniconimg {
  width: 40%;
  vertical-align: middle;
  margin-top: -4px;
}

.controllButtonsFloating {
  width: max-content;
  align-items: center;
  margin: 0 auto;
}
.patient-profile .controllButtonsForCallNew button {
  margin: 0px 5px;
  width: auto !important;
  height: auto !important;
}
.clearfix {
  clear: both;
}
.PprofileAddr label {
  margin-bottom: 0px;
}
.PprofileAddr p {
  margin-bottom: 0px;
}
.hidden {
  display: none;
}
.Flip_Camera_DIV {
  width: 300px;
  position: absolute;
  bottom: 75px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: -19%;
  cursor: pointer;
}
.Flip_Camera_DIV .modal-header {
  padding: 7px 15px;
  cursor: pointer;
}
.Flip_Camera_DIV .modal-header span {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 57px;
  padding: 6px;
}
.Flip_Camera_DIV .list-camera {
  padding: 8px 15px 0px 15px;
}
.opcity_2 {
  opacity: 1 !important;
}
.Flip_Camera_DIV .list-camera .form-group {
  margin-bottom: 0.3rem;
  height: initial;
  font-size: 14px;
}
.Flip_Camera_DIV .list-camera .form-group .form-control {
  height: initial;
  font-size: 14px;
  padding: 0.4875rem 1.0625rem;
}

/*particpate start*/
.participate_DIV {
  width: 300px;
  position: absolute;
  bottom: 75px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 80%;
  cursor: pointer;
}
.participate_DIV .modal-header {
  padding: 7px 15px;
  cursor: pointer;
}
.participate_DIV .modal-header span {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 57px;
  padding: 6px;
}
.participate_DIV .list-camera {
  padding: 8px 15px 0px 15px;
}
.participate_DIV .list-camera .form-group {
  margin-bottom: 0.3rem;
  height: initial;
  font-size: 14px;
}
.participate_DIV .list-camera .form-group .form-control {
  height: initial;
  font-size: 14px;
  padding: 0.4875rem 1.0625rem;
}
/*participate end*/

.overwrite-circle1 {
  width: 0px !important;
}
.overwrite-circle {
  width: 63px !important;
  text-align: center;
  height: 63px;
  line-height: 61px;
  margin-top: -0.7rem;
  font-size: 2rem;
  border-radius: 100%;
}
.myComentingBtn {
  background-color: #ebeced;
  color: #246ec6;
}
.myComentingBtn .fa-commenting-o {
  border-color: #246ec6;
  padding: 3.5px;
  color: #246ec6;
  border: 1px solid #246ec6;
  border-radius: 100%;
}
.adminVideoScreenMute {
  width: fit-content;
  height: fit-content;
  min-width: 18% !important;
  min-height: fit-content;
  border-radius: 100%;
  right: 0rem;
  top: 2.5rem;
}
.remoteVideoClass .adminVideoScreenMute video {
  width: 125px !important;
  display: none;
}
.adminVideoScreenMute .overwrite-circle {
  width: 60px !important;
  text-align: center;
  height: 60px;
  margin-top: unset;
  bottom: 10px;
  font-size: 2rem;
  border-radius: 100%;
  line-height: 61px;
  text-transform: uppercase;
}
.videoCallFullWidth .adminVideoScreenMute {
  width: fit-content;
  height: fit-content;
  min-width: 7% !important;
  min-height: fit-content;
  border-radius: 100%;
  right: 1rem;
  top: 3rem;
}
.videoCallFullWidth .adminVideoScreenMute .overwrite-circle {
  width: 80px !important;
  text-align: center;
  height: 80px;
  margin-top: -0.7rem;
  font-size: 2.5rem;
  border-radius: 100%;
  line-height: 76px;
}
.mtTabination .PrivateTabIndicator-root-2 {
  height: 5px;
  background-color: #318086;
  width: 12.4% !important;
  margin-left: 12px;
}
.margonTopmines {
  position: absolute;
  right: 1rem;
  top: 1.3rem;
}
.mtTabination .MuiTabs-flexContainer {
  background-color: #ffffff;
}
.mtTabination .MyTabPanel {
  background-color: #ffffff;
}
.associInputSearch {
  width: 50%;
  border-radius: 0px;
  height: calc(1.2em + 1.375rem + 1px);
  padding: 0.2575rem 0.5625rem;
  background-color: transparent;
}
.AssociIconButton {
  border: 1px solid #edeef6 !important;
  border-left: 0px !important;
  border-radius: 0px !important;
  padding: 8px 12px !important;
  top: -2px;
}
.familyMambersListMainDiv span {
  font-size: 14px;
}
.margonTopminesMobile {
  position: absolute;
  right: 2rem;
  top: 0.6rem;
}
.hidden-xs {
  display: inline-block !important;
}
.actionBtn {
  width: 14%;
}
ul.profile_dropdown {
  transform: unset !important;
  top: 1.7rem !important;
  right: -1.2rem !important;
  left: unset !important;
  padding-top: 0px !important;
}
.img-rounded {
  border-radius: 100%;
}
.DoctProfilPatientImg {
  padding: 4px;
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 225);
  transition: all 0.2s ease-in-out;
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  box-shadow: 1px 6px 15px rgba(144, 144, 144, 0.28);
}
.profileDetailsDivImg {
  width: 120px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.profileDetailsDivContent {
  display: inline-block;
  vertical-align: middle;
}
.profileDetailsDivContent .doctor-name {
  margin-bottom: 0px;
}
.bgaTrans {
  background-color: transparent;
}
@media (min-width: 1800px) {
  .doctor-profile-screen .fb-profile-flex .fb-image-profile {
    width: 210px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .dashboard-content-section .box-section {
    width: auto;
    padding: 20px 20px;
    margin-right: 20px;
  }
  .dashboard-content-section .box-section.active {
    padding: 20px 20px;
    margin-right: 15px;
  }
  .go-btn {
    right: 1em;
  }

  .reminder-fields label {
    padding: 5px 10px;
    font-size: 14px !important;
  }
}

.font-15 {
  font-size: 15px;
  font-weight: 100;
}

/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
} */

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #246ec6;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 25px;
  font-size: 12px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #246ec6 transparent transparent transparent;
}

.actn_btn:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.navigation .nav li:hover a {
  color: #0176ff !important;
}
.my-row {
  width: 100%;
  display: flex;
}
.sign-bga {
  background-color: #246ec63b;
}
.my-library-btn {
  width: 100%;
  border-radius: 3px !important;
  padding: 0.2925rem 0.75rem;
}
.my-form-btn {
  width: 100%;
  padding: 0.1925rem 0.75rem;
  border-radius: 3px !important;
}

.my-btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-input-height .form-control {
  height: 41px;
}
.my_medical_his {
  margin-left: 1rem;
}
.gradient-border {
  border-width: 3px;
  position: relative;
  border-radius: 2px;
}

.myEditBtn {
  border-radius: 100% !important;
  position: absolute;
  right: 11px;
  top: -12px;
}
.Myimage-parent .react-thumbnail-generator img {
  width: 33% !important;
}
.Myimage-parent .middle {
  width: 33% !important;
  height: 68% !important;
}
/* Toggle Button */
.checkbox label .toggle,
.checkbox-inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}
.toggle {
  position: relative;

  overflow: hidden;
}
.toggle input[type="checkbox"] {
  display: none;
}
.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.toggle-group .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc !important;
}
.toggle .toggle-group .btn-primary {
  background-color: #0176ff !important;
  border-color: #0176ff !important;
}

.toggle-group .btn {
  display: inline-block;
  padding: 5px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.toggle.off .toggle-group {
  left: -100%;
}
.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 0;
  border-width: 0 1px !important;
}

.toggle.btn {
  min-width: 59px;
  min-height: 34px;
}
.toggle-on.btn {
  padding-right: 24px;
}
.toggle-off.btn {
  padding-left: 24px;
}
.toggle.btn-lg {
  min-width: 79px;
  min-height: 45px;
}
.toggle-on.btn-lg {
  padding-right: 31px;
}
.toggle-off.btn-lg {
  padding-left: 31px;
}
.toggle-handle.btn-lg {
  width: 40px;
}
.toggle.btn-sm {
  min-width: 50px;
  min-height: 30px;
}
.toggle-on.btn-sm {
  padding-right: 20px;
}
.toggle-off.btn-sm {
  padding-left: 20px;
}
.toggle.btn-xs {
  min-width: 35px;
  min-height: 22px;
}
.toggle-on.btn-xs {
  padding-right: 12px;
}
.toggle-off.btn-xs {
  padding-left: 12px;
}
/* Virtual Invocation Modal */
.invocation-form .form-group {
  margin-bottom: 10px;
}
.invocation-form {
  margin-top: 5px !important;
}
.invocation-form label {
  font-size: 12px !important;
  margin-bottom: 5px !important;
}
.invocation-form .css-yk16xz-control {
  height: 35px;
  font-size: 12px;
  min-height: 35px;
}
.invocation-form input[type="text"] {
  font-size: 12px;
}

.invocation-table-body td {
  font-size: 13px;
}
.invocation-table-head th {
  border: 0;
  border-bottom: 0 !important;
}

/* .invocation-table-body .invocation-cell-border td {
  border: 3px solid grey !important;
} */
.invocation-table-body,
.invocation-table-head {
  text-align: center;
}
.invocation-table-body .invocation-group-avtar {
  background: #fff;
  border-radius: 50%;
  width: 35px;
}
.invocation-table-body .invocation-cell {
  background: #e3f0fe;
  border: none !important;
}
.invocation-table-body .invocation-cell:hover {
  background: #d2e5f9;
}
.invocation-pick-action {
  border: none;
  color: white;
  background: #226cc5;
  padding: 2px 10px;
}
.invocation-pass-action {
  border: none;
  color: white;
  background: red;
  padding: 2px 12px;
}
.invocation-group-name {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Save Button Loader Image */
.save-btn-loader {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-right: 5px;
}
/* ====================================NEW CSS 2-26-2021 =================== */
.side-by-side {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 12%; */
}
/* .mobile-view {
  display: none !important;
} */
.hide-on-mobile {
  display: inline-block !important;
}
.side-by-side > * {
  width: 49%;
}
.width-12-5 {
  width: 12.5% !important;
}
.width-auto {
  width: auto !important;
}
.no-data-message {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10%;
}
.background-override {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 1px grey;
  overflow: hidden;
  min-height: 70vh;
}
.tab-header-override {
  background-color: white !important;
}
.dashboard-content-section {
  min-height: 100vh;
}
.analytics-matrix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-matrix {
  margin-left: 15;
}
.sub-matrix * {
  align-self: flex-end;
  font-size: 14px;
}
.action-buttons-invite {
  display: flex;
  width: 30%;
  position: absolute;
  right: 10px;
  top: 10px;
}
.action-button-child {
  /* height: 40px; */
  margin-right: 10px; /* min-width: 80px; */
}

.beep-image {
  width: 30px;
}
.attention {
  animation: infinite 1s attention;
}
.new-tables tbody tr td {
  justify-content: left !important;
  vertical-align: middle;
  font-size: 14.5px !important;
}
/* #patientDetails .table tr td:first-child {
  padding-right: 0px !important;
} */
.mypati_minWidth {
  width: 107px;
  max-width: 107px;
  min-width: 107px;
}
.pati_fixedWidth1 {
  width: 57px;
  max-width: 57px;
  min-width: 57px;
}
.pati_fixedWidth2 {
  width: 107px;
  max-width: 107px;
  min-width: 107px;
}
.pati_fixedWidth3 {
  width: 93px;
  max-width: 93px;
  min-width: 93px;
}
.pati_fixedWidth4 {
  width: 83px;
  max-width: 83px;
  min-width: 83px;
}
.pati_fixedWidth5 {
  width: 90px;
  max-width: 90px;
  min-width: 90px;
}

.mx-w-auto {
  max-width: fit-content !important;
}
/* .myavatarDiv .charLimt .p {
  max-width: 111px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
} */
@media (min-width: 1300px) {
  .mypati_minWidth {
    width: 107px;
    max-width: 107px;
    min-width: 160px;
  }
  .charLimt p {
    display: inline-block;
    max-width: 105px;
    white-space: pre-wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }
  .side-by-side {
    flex-wrap: wrap;
  }
  .background-override {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .side-by-side {
    padding: 0px 14px;
  }
  .text-ellipsis {
    max-width: 97px;
  }
  .background-override {
    margin-bottom: 1rem;
  }
  .myBackground .my-border li:first-child {
    width: 100%;
  }
  .appo-requestDive {
    position: relative;
    display: inline-block !important;
    width: 100%;
  }
  .appo-requestDive .first-half {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 100%;
    margin-right: auto;
    width: 100%;
  }
  .appo-requestDive .second-half {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .appo-requestDive .action-buttons-new {
    display: flex;
    height: 40px;
    width: 100%;
    margin-right: -11px;
  }
  .mb-xs-3 {
    margin-bottom: 10px;
  }
}
.checklists input[type="radio"]:checked.checklists-radio-no + label {
  background-color: #dc3545;
}
.pac-container {
  z-index: 10000 !important;
}
/* sandip css */
.errorScreen {
  top: 54px !important;
}
.P_side_vi_control {
  opacity: 1;
}
.chat-body .nav-pills {
  display: flex !important;
  flex-wrap: nowrap;
  overflow: auto;
}
.nav-pills .nav-link {
  white-space: nowrap;
  position: relative;
  padding: 10px 15px !important;
}
.nav-pills .nav-link .badge {
  position: absolute !important;
  right: -5px;
  padding: 5px !important;
  top: 4px;
}
.modal-open .modal {
  background: rgba(0, 0, 0, 0.5);
}
.ui-draggable .loaderX {
  background-color: #000 !important;
}
body .initials-box {
  background-color: #000 !important;
}
.patient-info {
  position: relative;
  display: flex;
  align-items: center;
}
.patient-info .media {
  width: 90%;
  flex: 0 0 auto;
  margin-bottom: 0px !important;
}
.rightcontent {
  flex: 0 0 auto;
  width: 100px;
  text-align: center;
}
.leftcootent {
  flex: 0 0 auto;
  width: calc(100% - 100px);
}
.font16 {
  font-size: 16px !important;
}
.avatar-multi .avatar-img {
  border: 1px solid #ddd;
  border-radius: 50%;
}
.avatar-multi:nth-of-type(2),
.avatar-multi:nth-of-type(3) {
  margin-left: -11px;
}
.avatar.avatar-offline::before,
.avatar.avatar-online::before {
  height: 12px !important;
  min-height: 12px !important;
  width: 12px !important;
  min-width: 12px !important;
}
@media (max-width: 767px) {
  #listOfAppoitnment .colorfff {
    background-color: transparent;
    padding: 0px;
    min-height: auto;
    margin-top: 10px;
  }
  .nav-pills .nav-link {
    padding: 10px 8px !important;
    font-size: 14px;
  }
  a.nav-link.active.user-console::after {
    left: 8px;
  }
  .incomingwalking .invocation-cell {
    display: flex !important;
    flex-wrap: wrap;
  }
  .incomingwalking td {
    display: inline-block;
    border-top: none;
  }
  .incomingwalking td:nth-of-type(1) {
    flex: 0 0 auto;
    width: 56px;
  }
  .incomingwalking td:nth-of-type(2) {
    flex: 0 0 auto;
    width: calc(100% - 60px);
  }
  .incomingwalking td:nth-of-type(3) {
    text-align: center;
    flex: 0 0 auto;
    width: 100%;
  }
  .iincomingwalking .invocation-cell {
    background: #e3f0fe;
    border-bottom: 1px solid #ddd !important;
  }
  .background-override {
    min-height: 290px;
  }

  .controllButtonsForCallNew {
    position: static !important;
    top: 0px;
    right: 0;
    z-index: 99;
    padding: 8px;
    border-top: 1px solid #ddd;
    width: 100%;
  }
  .patient-profile .controllButtonsForCall button {
    margin-right: 10px !important;
  }
}
#virtualInvocationBtn :disabled {
  background-color: #17a2b8;
  border: 1px solid #17a2b8;
  cursor: not-allowed;
  color: #fff;
}
.background-admin {
  width: 97%;
  margin: 0 auto 0 auto;
  background: "#fffff";
}
.background-admin-tabs-background {
  margin: 0 !important;
  background-color: #ffffff;
  border-radius: 13px;
}
.websiteDisplay {
  margin-left: -16rem !important;
}
