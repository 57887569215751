.modal_flex_layout{
    width: 100%;
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
}
.formStyle{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .btnGreen {
    width: 60%;
    min-width: 300px;
    padding: 7px 0px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    background-color: var(--green);
    border-color: var(--green);
  }