.toggebtn{
  position: absolute;
    // left: 180px;
    font-size: 20px;
    left: 30px;
}

.toggle{
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.btnWithAddon {
  overflow: hidden;
  position: relative;
  padding: 10px !important;
  margin: 5px !important;
  // padding-left: 10px !important;
  border: 1px solid var(--black);
  border-radius: 10px;
  width: 100px;
  text-align: center;
  font-size: 16px !important;
  font-weight: 600;
  background-color: #fff;
  color: var(--black);
  // padding-top: 5px;
  // max-width:160px;

  .btnAddon {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    background-color: rgba(white, 0.2);
    width: rem(40);
  }

  .btnAddonIcon {
    font-size: rem(16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.todayBox{
  padding: 10px;
}

.todayBox :hover{
  padding: 10px;
  border-radius: 10px;
  background-color: #e6ecff;
  cursor: pointer;
  // color: white;
}


.container{
  padding-left: 15px;
  padding-right: 15px;
}

.cardDiv{
  background-color: #F5F5F5 !important;
  border-radius: 5px !important;
  border: 1px solid var(--gray-border) !important;
  padding-left: 15px;
  padding-right: 15px ;
  
}
.cardDiv2{
  background-color: var(--light-primary-bg-color) !important;
  border-radius: 5px !important;
  border: 1px solid var(--gray-border) !important;

}

.borderBottomRightCol{
 height: 90px;
  // border:
  // 1px solid #CCCCCC;
  border-bottom:
  1px solid #CCCCCC;
}
.borderBottomCol{
  border-bottom:
  1px solid #CCCCCC;
}

.borderCol{
  height: 90px;
   // border:
   // 1px solid #CCCCCC;
   border-bottom:
   1px solid #CCCCCC;
 }
.h5Text{
  color: var(--black) !important;
  font-weight: 700;
  font-size: 16px;
}


@media screen and (max-width: 574px) {

  .borderCol{
    height: 0px !important;
    border-bottom: none !important;
  }
  
}

@media (min-width: 900px){
  .heading{
    padding-left: 20px;
  }
  .ratingDiv{
    padding-left: 20px;
  }
}


@media only screen and (max-width: 770px) {
  .div_btn{
    padding: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .btnWithAddon {
    
  font-weight: bold;
  font-size: 16px !important;
 
  }
}
