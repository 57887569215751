.customBtn {
  width: 50px;
  border-radius: 10px !important;
  margin-right: 10px;
}

.event {
  margin-top: rem(5);
  margin-bottom: rem(5);
  margin-right: rem(20);
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
}

.steps {
  width: 100% !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  font-size: 12px;
}

.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px !important;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.toggebtn {
  position: absolute;
  // left: 255px;
  font-size: 20px;
}

.toggle {
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.text {
  text-transform: lowercase;
}

@media only screen and (device-width: 375px) and (device-height: 812px) {
  .text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1277px) {
  // .Sidebar_div {
  //   padding-right: 10px !important;
  // }
}

.textNormal {
  text-transform: lowercase;
  display: block;
}

.textNormal::first-letter {
  display: block;
  text-transform: uppercase !important;
}