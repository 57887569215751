.contentSelect {
  height: 70vh;
}

.productImg_appoiment {
  object-fit: cover;
  width: 50px;
}

.productImg1_appoiment {
  object-fit: cover;
  width: 50px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1a6ab1 !important;
}

.ant-radio-wrapper {
  align-items: center !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #1a6ab1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #1a6ab1;
}

.card-body {
  padding: 1.3rem !important;
}



.card_static_appiment {
  height: 110px;
  background-color: var(--lightgray1) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  padding: 0px !important;
}
.card_static_appiment_gray {
  height: 110px;
  background-color: #cecece !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  padding: 0px !important;
}

.div_edit {
  position: absolute;
  top: -10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconEdit {
  width: 20px;
}

.card_selected_appoiment {
  /* height: 120px; */
  height: 90px;
  border: 2px solid #1a6ab1 !important;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: white !important;
  padding: 0px !important;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 ;
  margin-bottom: 0;
}

.stepDiv {
  padding-top: 25px;
}
.stepsDiv{
  font-weight: 600;
}

.divPayment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0px 15px 0 !important;
}

.div_flex_appoiment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.div_inner_flex_appoiment {
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
}

.div_img {
  width: 25% !important;
  /* padding: 20px; */
  display: flex;
  /* border-right: 2px solid rgba(0, 0, 0, 0.1); */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%
}

.div_label_center {
  width: 75% !important;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  /* padding: 20px; */
}

/* .div_label_center_radio{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */
.h33 {
  margin: 0px !important;
}

.pp {
  margin: 0px !important;
}

.listfamilylbl {
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {
  .div_inner_flex {
    margin-right: 10px;
    margin-left: 10px;
  }
}

/* .ant-steps-item-icon, .ant-steps-item-content {
  display: inline-block !important;
  vertical-align: baseline !important;
} */
.card-body {
  padding: 1.3rem !important;
}

.card_static {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #e6e6e6 !important;
  border: 5px solid white !important;
  padding: 25px;
}

.card_selected {
  border: 5px solid #1a6ab1 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #e6e6e6 !important;
  padding: 25px;
}

.div_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-right: 0px !important;
}

.productImg {
  /* width: 160px;
  height: 150px !important; */
  object-fit: cover;
  width: 100%;
}

.btn {
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}

.btnBg1 {
  /* width: 200px; */
  border-radius: 5px;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid #1a6ab1 !important;
  color: #1a6ab1 !important;
  height: 45px;
  font-size: 16px;
  background-color: #ffffff !important;
}



.div_row_name {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
}


.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px !important;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: #CED4DA!important;
}
.breadcrumbDiv{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}
.headerTop{
 
  background-color: #B476D3 ;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
 
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.checkRadio {
  margin-top: 30px;
  padding: 30px;
  border: 1px solid gray;
  border-radius: 10px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
}

.lblPayment {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.chooseApp {
  cursor: pointer;
}

.chooseApp2 {
  cursor: pointer;
}

.div_flex_waiting {
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.col_flex {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}




.div_inner_flex {
  width: 28%;
  margin-right: 10px;
  margin-left: 20px;
  cursor: pointer;
  padding-top: 15px;
  /* padding-bottom: 150px; */
}

.div_inner_flex_Dynamic {
  /* width: 30%; */
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.div_card_main {
  width: 100%;
  background-color: #A9D3BF;
  border-radius: 10px;
  padding: 15px;
  padding-top: 55px !important;
  height: 220px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.lbl_card {
  color: #36343C !important;
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

.lbl_card_name {
  color: #36343C !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.div_card_below {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.input_card {
  color: #000000;
  padding: 0px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.input_card_without_radius {
  color: #000000;
  padding: 0px;
  padding-left: 10px;
  margin-bottom: 0px;
  border-color: transparent !important;
}

.input_card_vcode {
  color: #000000;
  width: 100%;
  padding: 0px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.iconCheck{
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 25px;
  color: green;
}
@media (min-width: 320px) and (max-width: 480px) {
  .div_inner_flex_appoiment {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 760px) {
  .div_inner_flex_appoiment {
    width: 80%;
  }
}

@media (min-width: 761px) and (max-width: 900px) and (orientation: landscape) {
  .div_inner_flex_appoiment {
    width: 80%;
  }

  .chooseApp2 {
    width: 357px !important;
  }

  .div_label_center_radio {
    margin-left: 10px !important;
  }
}

@media (min-width: 767px) and (max-width: 882px) and (orientation: landscape) {
  .div_inner_flex_appoiment {
    width: 58%;
  }

  .chooseApp2 {
    width: 357px !important;
  }

  .div_label_center_radio {
    margin-left: 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 1000px) and (orientation: landscape) {
  .div_inner_flex_appoiment {
    width: 50%;
  }

  .chooseApp2 {
    width: 357px !important;
  }

  .div_label_center_radio {
    margin-left: 10px !important;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
  .div_inner_flex_appoiment {
    width: 60%;
  }

  .chooseApp2 {
    width: 357px !important;
  }

  .div_label_center_radio {
    margin-left: 10px !important;
  }
}


/* @media (min-width:1616px) and (max-width:1920px) {
  .chooseApp {
    width: 430px !important;
  }
  .card_static_appiment{
    width: 200px !important;
  }

}


} */

@media (min-width:1200px) and (max-width:1350px) {

  .div_label_center_radio {
    width: 300px !important;
  }

  .chooseApp2 {
    width: 357px !important;
  }

  .div_label_center_radio {
    margin-left: 10px !important;
  }

}

[role="button"] {
  cursor: default !important;
}

.newClass{
  top : 0;
  max-width: none;
  height:100vh;
  background:white;
}
.btnCancel{
  width: 200px;
  margin-top: 20px;
}

.btnGreen{
  width: 200px ;
  background-color:var(--green);
  border-color:var(--green);
  color: #fff;

}
.btnGreen:hover{
  width: 200px ;
  background-color:var(--green);
  border-color:var(--green);
  color: #fff;
}