.container {
  width: 100%;
}

.card_header_new {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: left !important;
  height: 100%;
  width: 100%;
}

.address {
  display: flex;
}

.blueBtn {
  width: 200px !important;
  border-radius: 5px !important;
  background-image: linear-gradient(to right,
      #567cd8 0%,
      #5987f7 51%,
      #4573e4 100%);
  color: white !important;
}

.cancleBtn {
  width: 200px !important;
  border-radius: 5px !important;
}

/* .avtarDiv {
  position: relative;
  img {
    object-fit: cover;
    width: 130px;
    height: 130px;
    border-radius: 0%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
} */
.editIconDiv {
  position: absolute;
  bottom: 7px;
  right: -3px;
  background-color: #595c97;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon_pen {
  font-size: 15px;
  color: white;
  padding-left: 3px;
  padding-bottom: 8px;
  position: absolute;
  padding-top: 8px;
}

.uploadBtn {
  position: absolute;
  display: flex;
}

.inputFile {
  padding: 1px !important;
  opacity: 0 !important;
  margin-top: 10px;
  z-index: 99;
}

.dropdown {
  border: none !important;
  height: 100%;
  width: 100%;
}

.dashboard_eventcard {
  max-height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.groupofcard {
  flex-wrap: wrap;
}

.description_title {
  color: #4a4a4a;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.description {
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  /* height: 110px; */
  white-space: break-spaces;
  overflow: hidden;
}

.description p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.locations {
  word-break: break-all;
  /* height: 61px; */
  overflow: hidden;
}

.datetimebox {
  display: flex;
  color: #888;
  font-size: 13px;
  padding: 7px 12px 0px;
}

.date {
  margin-right: 6px;
}

.deleteIcon {
  /* display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff; */
  color: white;
  font-size: 20px;
  position: absolute;
  right: 0px;
  top: 15px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.setheight {
  height: 125px;
}

.author img {
  width: 121%;
  border-radius: 50%;
}

.event .author .profileImage {
  position: absolute;
  background: white;
  padding: 3px;
  width: 5em;
  border-radius: 50%;
  border: 1px solid #ccc;
  box-sizing: content-box;
  z-index: 1;
  top: 4px;
}

.facilitorname {
  font-size: 9px;
  font-weight: 700;
  color: white;
  padding-top: 5px;
  text-align: center;
  width: 59px;
}

.event_name {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-left: 0;
  max-width: 260px;
  align-items: center;
  display: flex;
  justify-content: center;
  word-break: break-all;
}

.transbox {
  padding: 5px 5px;
  background-color: #ffffff;
  opacity: 0.6;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.button {
  border: 1px transparent;
  padding: 0%;
  width: 100%;
  cursor: pointer;
  border-radius: none !important;

  font-family: Arial;

  text-align: center;
  text-decoration: none;

  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

.joinNow {
  border-radius: 0px !important;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #bbbbbb;
    -webkit-box-shadow: 0 0 3px #bbbbbb;
  }

  50% {
    background-color: #bbbbbb;
    -webkit-box-shadow: 0 0 3px #bbbbbb;
  }

  100% {
    background-color: #bbbbbb;
    -webkit-box-shadow: 0 0 3px #bbbbbb;
  }
}

@keyframes glowing {
  0% {
    background-color: #bbbbbb;
    box-shadow: 0 0 10px #bbbbbb;
  }

  50% {
    background-color: #bbbbbb;
    box-shadow: 0 0 15px #bbbbbb;
  }

  100% {
    background-color: #bbbbbb;
    box-shadow: 0 0 3px #bbbbbb;
  }
}

.svg-btn {
  display: block;
  width: 230px;
  height: 230px;
  margin-left: 10px;
}

svg {
  -webkit-animation: glowing-polygon 1300ms infinite;
  -moz-animation: glowing-polygon 1300ms infinite;
  -o-animation: glowing-polygon 1300ms infinite;
  animation: glowing-polygon 1300ms infinite;
}

.filterSwitch {
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  min-height: 10px;
  padding-top: 0px;
}

.toggebtn {
  position: absolute;
  font-size: 20px;
  left: 30px;
}

.toggle {
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.more {
  color: #1a6ab1 !important; /* Blue color similar to the default link color */
  text-decoration: underline; /* Add underline to mimic default link text-decoration */
  cursor: pointer; /* Change cursor to pointer on hover to indicate clickability */
}

.more:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline; /* Keep underline on hover */
}