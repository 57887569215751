.contentSelect {
  height: 70vh;
}

.productImg {
  width: 50px;
  margin-bottom: 10px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1a6ab1 !important;
}

.ant-radio-wrapper {
  align-items: center !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #1a6ab1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #1a6ab1;
}

.doctor-widget {
  align-items: center !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  text-align: left !important;
}

.card-body {
  padding: 1.3rem !important;
}

.card_static {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px !important;
  height: 145px !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #fff !important;
  border: 5px solid white !important;
  padding: 5px !important;
}

.card_selected {
  margin-right: 20px;
  border: 2px solid #1a6ab1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px !important;
  height: 145px !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #fff !important;
  padding: 5px !important;
}

.div_btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end !important;
  padding-right: 20px !important;
  padding-bottom: 20px;
}

.btn {
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 ;
  margin-bottom: 0;
}
.box {
  margin-top: 20px;
  background-color: #F9FAFD;
  height: 85px;
  width: 100%;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

.pageInfo {
  color: #0071DC;
  font-size: 25px;
  font-weight: 700;
  margin: 0px;
}

.h5New {
  color: #000 !important;
  font-size: 15px;
  margin: 0 0 8px;
}

.radioCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepDiv {
  padding-top: 25px;
}

.divPayment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0px 35px 0;
}

.div_flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.div_inner_flex {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.box {
  margin-top: 5px !important;
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
}

.pageInfo {
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .div_flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .div_inner_flex {
    width: 60%;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.reasonDiv {
  /* margin-bottom: 25px; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 400px;
}

.arrowIcon {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}

.ant-form-item-label {

  display: block !important;
}

.ant-form-item-label .ant-form-item-required {
  display: block !important;
}

.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


// @media (max-width: 900px) {
  .btnContainer {
    .btnBox1 {
        display: flex;

        button {
          margin-bottom: 10px;
        }

        .btnBg2 {
          width: 90px !important;
        }

        .btnBg1 {
          width: 100px !important;
        }

        .submitBtn {
          width: 110px !important;
        }
    }

    .btnBox2 {
      display: flex;
      align-items: flex-start;
    }

    button {
      margin-bottom: 10px;
    }

    .btnBg2 {
      width: 90px !important;
      background-color: rgb(229, 57, 53) !important;
      border-color: rgb(229, 57, 53) !important;
    }

    .btnBg1 {
      width: 100px !important;
    }

    .submitBtn {
      width: 110px !important;
    }
  }
// }

.newClass{
  top : 0;
  max-width: none;
  height:100vh;
  background:white;
}

.btnCancel{
  width: 200px;
  margin-top: 20px;
}

.div_flex_waiting {
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.col_flex {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}