
.div_container{
  width: 100%;
  height: 100vh;
  background-color: #F2F4F7 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.div_content{
  width: 450px;
  background-color: white;
  /* height: 550px; */
  /* height: 590px; */
  height: 750px;
}
.div_image{
  width: 450px;
  background-image: url("../../images/imgs/back.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 55px;
}
.div_round{
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  bottom: -60px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgb(0,0,0,0.1);
}
.imgFluid{
  width: 70px;
}
.p_name{
  font-size: 20px;
  color: white !important;
  font-weight: bold;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
.p_desc{
  font-size: 15px;
  color: white !important;
  margin: 0px !important;
  margin-top: 20px;
  text-align: center;
}
.div_form{
  width:  100%;
  padding: 40px;
  padding-top: 100px !important;
}
.dontHave{
  margin-top: 20px;
}
.copy{
  font-size: 14px;
  margin-top: 10px !important;
  display: block;
  margin-bottom: 50px;
  position: relative;
  top: 150px
}

.ant-spin-dot-item {
  background-color: white !important;
}
.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05) !important;
  color: #F2F4F7 !important;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
 .div_container{
  height: 100% !important;
  padding: 10px;
  margin: 10px;
  padding-top: 0 !important;
  margin-top: 0 !important;
 }
}
