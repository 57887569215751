.modal_flex_layout{
    width: 100%;
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
}


.btnBrowse {
    width:fit-content;
    border-radius: 10px !important;
    border-color: #ffffff !important;
    border: 1px solid #000000 !important;
    color: #000000 !important;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    background-color: #ffffff !important;
    padding: 0px 30px;
    
  }