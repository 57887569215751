.container {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-sm {
  padding-top: 8px !important;
  font-size: 16px !important;
  width: 100px !important;
  height: 50px !important;
}

.addBtn {
  width: 200px !important;
}

.iconn {
  font-size: 15px !important;
}

.imgeAll {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.iconComment {
  font-size: 23px !important;
  color: #1a6ab1;
}
.iconPaperGray {
  font-size: 30px !important;
  color: #ccc;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1a6ab1 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #1a6ab1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #1a6ab1;
}

.doctor-widget {
  align-items: center !important;
}

.doc-info-right {
  margin-left: 0px !important;
  width: 100% !important;
  flex: 1 !important;
  max-width: 100% !important;
}

h6 {
  color: black !important;
  font-size: 13px !important;
  font-weight: normal !important;
  margin-bottom: 8px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: pre-wrap !important;
}

.card-body {
  padding: 1.3rem !important;
}

.card {
  background-color: white;
  margin-bottom: 1rem !important;
  /* box-shadow: 0 3px 5px rgb(0 0 0 / 0.1); */
}

.div_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end !important;
  padding-top: 20px;
  padding-bottom: 30px;
}

.btnDashboard {
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0;
  margin-bottom: 0;
}
.doctor-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.doc-info-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.doc-info-right {
  margin-left: auto;
  -ms-flex: 0 0 205px;
  flex: 0 0 205px;
  max-width: 205px;
}

.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px !important;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
}

.pageInfo {
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.customButton {
  width: 80px !important;
  height: 35px !important;
  font-size: 13px !important;
  border: none !important;
}

.div_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
}

.div_book_appoiment {
  position: absolute;
  right: 20px;
  top: 20px;
}

.btn_book {
  width: 100%;
}

.dependent_center {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.btn_all_header {
  padding-top: 11px !important;
}

.spinner {
  color: var(--primary-color);
}

.pdf_viever_padding {
  padding: 13px;
}

.buttonView {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer !important;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 5px 25px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 110px;
}

.buttonView1 {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer !important;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 0px 5px 2px 5px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* width: 80px; */
}

/* .button-View:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
} */
.buttonJoin {
  background-color: #fafafa;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer !important;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonJoinOriginal {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonJoin_original_disabled {
  background-color: lightgray;
  border: 1px solid lightgray;

  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-Delete {
  background-color: #f83030;
  border: 1px solid #ff0000;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 14px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-Edit-Icon {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 12px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-Edit-Icon:hover {
  /* box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0; */
  transform: translateY(-2px);
}

.tag_dashboard2 {
  width: 100px;
  text-align: center;
  padding: 6px !important;
  font-size: 12px;
  color: #051c3499 !important;
  /* cursor: pointer; */
}

.tag_dashboard {
  width: 100px;
  text-align: center;
  padding: 6px !important;
  font-size: 12px;
  color: white !important;
  /* cursor: pointer; */
}

.tag_dashboard_completed {
  width: 100px;
  text-align: center;
  padding: 6px !important;
  font-size: 12px;
  background-color: rgb(65, 101, 219) !important;
  color: #e9ecef !important;
  /* cursor: pointer; */
}

.yellow_dashboard {
  background-color: #ffb319;
  color: white;
  border-color: #ffb319;
}

.green_dashboard {
  background-color: #91d18b;
  color: white;
  border-color: #91d18b;
}

.gray_dashboard {
  background-color: #716f81;
  color: white;
  border-color: #716f81;
}

.toggebtn {
  position: absolute;
  left: 30px;
  font-size: 20px;
}

.toggle {
  position: absolute;
  z-index: 99;
  min-width: 300px;
}

.tag {
  color: #194f85 !important;
  margin-top: 5px !important;
}

.btnWithAddon {
  overflow: hidden;
  position: relative;
  padding: 5px !important;
  margin: 5px !important;

  border: none;
  width: fit-content;
  font-size: 13px !important;
}

.btnAddon {
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  background-color: rgba(white, 0.2);
  width: rem(40);
}

.btnAddonIcon {
  font-size: rem(16);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* New */

.D_Profile_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white !important;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  height: 278px;
}

.profile_div {
  /* background-color: white !important; */
  margin-bottom: 5px;
  /* margin-left:10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 278px;
}

.Pro_info_div {
  background-color: white !important;
  margin-bottom: 5px;
  padding: 15px 30px !important;
}

.style_leftBox__2TltT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.Segment_Div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 15px 0px; */
  background-color: white;
  margin: 10px;
  margin-left: 0;
  /* width: 500px; */
  /* padding-top: 25px; */
}

.file_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0px 25px;
}

.file_container_div {
  background-color: white !important;
  /* padding-top: 10px; */
  padding: 10px 0 24px 0;
  height: 278px;
  /* overflow: auto; */
}

.inner_div {
  height: 215px;
  overflow: auto;
}

.fileIcon {
  height: 30px;
  padding-bottom: 3px;
}

.file_in {
  margin: 0 10px;
  margin-top: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
}

.details {
  display: flex;
}

.lblName {
  display: none !important;
}

.lbl_color {
  color: #1c1f1f !important;
}

.text_color {
  color: black !important;
}

.btn_status {
  padding: 10px 20px !important;
}
.btn_day {
  width: 60px;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}
.text_day {
  color: white !important;
  font-size: 20px;
  margin: 0px !important;
}
.div_flex_start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
  .Segment_Div p {
    width: auto !important;
  }

  .Segment_Div {
    /* padding-top: 10px !important; */
    padding-left: 0 !important;
    /* max-width: 0 !important; */
  }

  .payment_div {
    /* width: 500px !important; */
    background-color: greenyellow;
  }

  .Pro_info_div {
    display: flex !important;
  }
}

@media only screen and (min-width: 75em) and (max-width: 80em) {
  .div_row_new {
    padding-left: 10px !important;
  }

  .card {
    margin-left: 10px !important;
  }

  .D_Profile_div {
    height: 100% !important;
  }
}

@media only screen and (max-width: 1300) {
  .Pro_info_div {
    padding: 10px !important;
    width: 500px !important;
  }
}

@media (max-width: 760px) {
  .Pro_info_div {
    /* padding: 20px !important; */
    margin: 10px !important;
    /* background-color: hotpink !important; */
  }
}

@media (max-width: 990px) {
  .file_container_div {
    /* background-color: khaki !important; */
    padding-top: 10px !important;
    margin-top: 10px !important;
  }

  .card {
    margin-top: 15px !important;
  }
}

@media only screen and (max-width: 1541px) {
  .relationship {
    display: none !important;
  }

  .lblName {
    display: block !important;
  }

  .details {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 998px) {
  .relationship {
    display: block !important;
  }

  .lblName {
    display: none !important;
  }
}

.NoDataImg {
  height: 50px;
  width: 50px;
}

/* For Chat Modal */
/* {box-sizing: border-box;} */

.chat_popup {
  position: fixed;
  /* bottom: 60px; */
  bottom: -30px;
  right: 15px;
  z-index: 99999;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open_button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.form_popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form_container {
  width: 450px;
  /* height: 800px; */
  background-color: white;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
  border-radius: 5px;
}

/* Full-width textarea */
.form_container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form_container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form_container .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form_container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form_container .btn:hover,
.open_button:hover {
  opacity: 1;
}
.btnTransparent {
  background-color: transparent !important;
  border-color: transparent !important;
  width: 50px;
}
.btnTransparentNew {
  background-color: transparent !important;
  border-color: transparent !important;
  width: 50px;
}
.cross_img {
  width: 30px !important;
  height: 30px !important;
}

.highlightedrowgreen {
  background-color: #699a69;
}

.highlightedrowyellow {
  background-color: rgb(237, 237, 68);
}

.highlightedrowred {
  background-color: rgb(241, 143, 143);
}

.highlightedrowblue {
  background-color: #89bae7;
}
.highlightedrow {
  background-color: #fff;
}

.appointmentdetails {
  font-weight: bold;
  color: #fff;
}

.appointmentdetails:hover {
  font-weight: bold;
  color: #000;
}

.iconchat {
  color: #000 !important;
}
.iconchat2 {
  color: #000 !important;
  font-size: 30px !important;
}

.div_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img_eye {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 10px;
}

.img_eyee {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 15px;
}

.icon_gray {
  color: gray; /* Set the color to gray */
}
.animated_tada_delay {
  font-size: 24px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  animation: tada-infinite-delay 3s infinite;
}

@keyframes tada-infinite-delay {
  0% {
    transform: scale(1) rotate(0);
  }
  10%,
  20% {
    transform: rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}
