.div_container {
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 700px;
  background-color: white;
  .div_doctor_details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0px;
    margin-left: 25px;
    // border-bottom: 1px solid lightgray;
    margin-right: 25px;
    .doc_img {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px;
      .img {
        display: flex;
        flex-direction: column;
      }
      .doc_name {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-left: 10px;
        color: black;
        min-width: fit-content;
        
      }
    }
    .div_date {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 20px 20px 10px 20px;
      .date {
        font-size: 15px;
      }
      .div_service {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .schedule_btn {
    margin-top: 35px;
    margin-bottom: 100px;
    .btn {
      margin: 0 20px 20px 20px;
      .timing_btn {
        width: 110px;
        height: 35px;
        background-color: transparent;
        border-radius: 5px;
        margin-left: 25px;
        margin-bottom: 20px;
        border-width: 1px;
      }
      .timing_btn:focus {
        background: #2196f3 !important;
        color: white;
        border-width: 1px;
      }

      .active_btn {
        width: 110px;
        height: 35px;
        border-radius: 5px;
        margin-left: 25px;
        margin-bottom: 20px;
        border-width: 1px;
        background: #2196f3 !important;
        color: white;
      }
      .active_btn:focus {
        background: #2196f3 !important;
        color: white;
      }
      // .timing_btn:visited {
      //     background: #1a6ab1;
      //     color: white;
      // }
    }
  }
}
.calander {
  margin-top: 50px;
  padding-left: 30px;
  height: fit-content;
  padding: 20px;
  background-color: white;
}

.specialist{
  width: 300px;
        overflow:hidden; 
        white-space:nowrap; 
        text-overflow: ellipsis;
}

@media (min-width: 982px) and (max-width: 651px) {
  .div_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .div_doctor_details {
      width: 100%;
      .doc_img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .doc_name {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .div_date {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        .div_service {
          padding-bottom: 20px;
        }
      }
    }
    .schedule_btn {
      width: 100%;
      .timing_btn {
        margin-left: 35px;
      }
      .active_btn {
        margin-left: 35px;
      }
    }
  }
  .calander {
    padding: 10px;
  }
}

.timing_btn_disable {
  width: 110px;
  height: 35px;
  background-color: gray;
  color: white;
  border-radius: 5px;
  margin-left: 25px;
  margin-bottom: 20px;
}
.timing_btn_not_available {
  width: 110px;
  height: 35px;
  background-color: rgb(231, 54, 54);
  color: white;
  border-radius: 5px;
  margin-left: 25px;
  margin-bottom: 20px;
}

.btnSpan {
  pointer-events: none;
  width: 110px;
  height: 35px;
  background-color: transparent;
  border-radius: 5px;
  /* margin-left: 25px; */
  /* margin-bottom: 20px; */
  border-width: 1px;
}

@media (min-width: 320px) and (max-width: 650px) {
  .div_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .div_doctor_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .doc_img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .doc_name {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .div_date {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        .div_service {
          padding-bottom: 20px;
        }
      }
    }
    .schedule_btn {
      width: 100%;
      .timing_btn {
        margin-left: 35px;
      }
      .active_btn {
        margin-left: 35px;
      }
    }
  }
  .calander {
    padding: 10px;
  }
}
@media (min-width: 375px) and (max-width: 886px) {
  .div_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .div_doctor_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .doc_img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        .doc_name {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .div_date {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        .div_service {
          padding-bottom: 20px;
        }
      }
    }
    .schedule_btn {
      width: 100%;
      margin-top: 10px;
      margin-right: 16px;
      .timing_btn {
        margin-left: 35px;
      }
      .active_btn {
        margin-left: 35px;
      }
    }
  }
  .calander {
    padding: 10px;
  }
}
.customCard {
  font-size: 15px;
  margin-bottom: 10px !important;
}



@media only screen and (max-width: 600px) {
  .div_date{
    padding: 0 !important;
    margin: 0 !important;
  }
  .doc_img{
    padding: 0 !important;
    margin: 0 !important;
  }
  .specialist{
    text-align: center !important;
  }
}

@media only screen and (max-width: 885px) {
  .div_date{
    padding: 0 !important;
    margin: 0 !important;
  }
  .doc_img{
    padding: 0 !important;
    margin: 0 !important;
  }
  .specialist{
    text-align: center !important;
  }
}
