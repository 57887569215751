.userProfile{
    width: 200px !important;
    height: 150px !important;
    border: 1px solid rgba(0, 0, 0, 0.5);
     border-radius: 100%;
     object-fit: cover;
}
.leftDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
    div{
        padding-right: 150px;
    }
}
.customBtnBorder{
    width: 50px !important;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid gray;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 

}
.badgesTest{
    padding: 15 !important;
    margin-right: 10 !important;
}
.leftBtn{
    display: flex ;
    flex-direction: row ;
    align-items: center ;
    justify-content: flex-end !important;
    // padding-right: 30px ;
}
.bookAppointmentBtn{
    width: 225px !important;
    display: flex ;
    flex-direction: row ;
    align-items: center ;
    justify-content: flex-end ;
}
.bookBtnDiv{
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 30px;
}
.docRight{
    display: flex;
    height: 200px;
    flex-direction: row;
    align-items: baseline;
    justify-content: end;
}

.div_language{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.div_inner_language{
    margin-top: 10px;
    width: 200px;
}
.iconArrowProfile{
    color: #62c1e5;
    font-size: 14px;
}
.div_title_decorate{
    background: lightgray;
    padding: 10px 17px;
    // padding-bottom: 3px;
    margin-bottom: 27px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}