.v_call_icon {
        font-size: 17px;
        border: 1px solid #bbbb;
        align-items: center;
        border-radius: 18px;
        padding: 5px;
        color: #5665d2;
}

.v_call_outer {
        border: 1px solid #5665d2;
        border-radius: 50px;
        padding: 10px;
}

.float_right {
        float: right;
}

.calling_ico {
        padding: 5px;
}

.inner_icon {
        border: 2px solid #5665d2;
        padding: 3px;
        border-radius: 10px;
        font-size: 17px;
}
.text_bold{
        font-weight: bold;
}
.ant-modal-header{
        background-color: #cecece !important;
}

.div_title_decorate_patient{
        background: lightgray;
        padding: 10px 17px;
        // padding-bottom: 3px;
        margin-bottom: 27px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
}