

:root{
  --primary-color :#8958A2;
  --primary-bg-color-light :#B476D3;
  --light-primary-bg-color: #F2E8F6;
  --primary-border-color : #8958A2;
  --primary-light-border-color : #B476D3;
  --green : #0E9A5F;
  --lightGreen1 : #E6FBF2;
  --lightgray1 : #F5F5F5;
  --gray-border : #D0D0D0;
  --black : #000;
}

.button-Join_original {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-Join_original_disabled {
  background-color: lightgray !important;
  border: 1px solid lightgray !important;
}
.btn-green{
  color: #fff;
  background-color: var(--primary-color);
  border-color: #1a6ab1;
}


.button-Delete {
  background-color: #f83030;
  border: 1px solid #ff0000;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 14px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1a6ab1 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #1a6ab1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #1a6ab1;
}

.card_selected {
  border: 5px solid #1a6ab1 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #e6e6e6 !important;
  padding: 25px;
}

.btnDashboard {
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}

.btnDashboardBook {
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
  width: 225px;
  color: white !important;
  height: 60px !important;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.button-Edit-Icon {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 12px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  /* height: 32px; */
  margin: 0;
  padding: 0 20px;
  color: rgb(255 255 255 / 85%);
  font-size: 14px;
  line-height: 30px;
  background: #747474;
  /* border: 1px solid #000000; */
  /* border-top-width: 1.02px; */
  /* border-left-width: 0px; */
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  border-radius: 3px !important;
  /* margin: 0px 12px 0px 0px; */
}

.card_selected_appoiment {
  /* height: 120px; */
  height: 110px;
  border: 1px solid var(--green) !important;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #E6FBF2 !important;
  padding: 0px !important;
}

.card_static_appiment {
  height: 110px;
  background-color: var(--lightgray1) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  padding: 0px !important;
}

.div_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-right: 0px !important;
}

.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 70px;
  width: 100% !important;
  color: white;
  padding-top: 20px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
  font-size: 12px;
}

.h5NewM {
  color: white !important;
  font-size: 12px;
  padding-left: 50px;
}

.pageInfo {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.pageInfoM {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding-left: 50px;
}

.footer {
  position: fixed !important;
  bottom: 0px !important;
  width: 100% !important;
  z-index: 99 !important;
}

.btnBg1 {
  /* width: 200px; */
  border-radius: 5px;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid #1a6ab1 !important;
  color: #1a6ab1 !important;
  height: 45px;
  font-size: 16px;
  background-color: #ffffff !important;
  padding-top: 7px !important;
}

.btnBgred {
  width: 200px;
  border-radius: 10px !important;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff !important;
  padding-top: 7px !important;
}

.btnBg2 {
  width: 200px;
  border-radius: 10px !important;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid var(--green) !important;
  color: #ffffff !important;
  height: 45px;
  font-size: 16px;
  background-color: var(--green)!important;
  padding-top: 7px !important;
}

.btnBg2_disabled {
  width: 200px;
  border-radius: 10px !important;
  margin-top: 0px !important;
  color: #ffffff !important;
  height: 45px;
  font-size: 16px;
  pointer-events: none;
  padding-top: 7px !important;
  background-color: #666666 !important;
}

.btnBgGreen {
  width: 200px;
  border-radius: 10px !important;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid var(--green) !important;
  color: #ffffff !important;
  height: 45px;
  font-size: 16px;
  background-color: var(--green)!important;
}
/* ---- */

.menu-bars {
  background: white;
}

.nav-menu {
  background-color: white;
  width: 370px;
  height: 16vh;
  display: flex;
  justify-content: center;
  position: fixed;
  flex-direction: column;
  top: 415px;
  left: -100%;
  transition: 850ms;
  z-index: 99999;
}

.badge {
  line-height: 1.5 !important;
  line-height: 1.5 !important;
  padding: 1px 7px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.lineSearchDoctor {
  margin-top: 0px !important;
  padding: 20px 0px 0px 20px;
  /* background-color: #3790ff21; */
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: var(--primary-color);
}

input {
  box-shadow: none !important;
}


.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #1a6ab1 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: #1a6ab1 !important;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: smaller;
}

.selectCardServices {
  border-color: #009efb;
  border-width: 1;
}

.btn-lg,
.btn-group-lg>.btn {
  font-size: 16px;
}

.react-tel-input .form-control {
  width: 100%;
}

.div_flex_HelthCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.div_flex_HelthCard_Center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container input {
  height: 35px;
}


.profpic {
  width: 60px;
  border-radius: 100%;
  object-fit: cover;
  height: 60px;
}

.profpic_D {
  width: 80px;
  border-radius: 10%;
  object-fit: cover;
}

.fontColor {
  color: #134f85;
  font-size: 14px;
  font-weight: bold;
}

.editIcon {
  height: 14px;
  margin: 5px;
  margin-right: 10px;
}

.search-emoji {
  display: none;
}

.selected-flag {
  display: none;
}

.flag-dropdown {
  display: none;
}

.react-tel-input .form-control {
  padding-left: 10px;
}

.speciality {
  width: 301px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-title {
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--primary-color) !important;
}

.ant-radio-wrapper {
  align-items: flex-start !important;
}

.ant-popover-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

}

.ant-steps-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 600px) {
  .tab-view .nav-item {
    width: 40% !important;
  }

  .smallDivices {
    flex-wrap: unset !important
  }

  .btnBg1 {
    width: 100px !important;
  }

  .btnBgred {
    width: 100px !important;
  }

  .heightDiv {
    height: 65vh !important;
  }
}

.phone_num {
  height: 20px !important;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .heightDiv {
    height: 65vh !important;
  }
}

.style_leftBox__2TltT {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* .ant-radio-button-wrapper{
  background-color: white;
  color: gray;
} */

/* .tab-view .nav-item.active{
  background: rgb(255, 255, 255);
    border: 1px solid white;
    border-bottom: 1px solid white !important;
    border-radius: 10px;
    columns: white !important;
    height: 35px;
    padding: 7px;
    margin-top: 10px;
}
.tab-view .nav-item{
  background: rgb(255, 255, 255);
    height: 35px;
    padding: 7px;
    margin-top: 10px;
} */

.tab_selected {
  /* background-color: rgb(255, 255, 255); */
  background-color: #009efb;
  padding: 6px;
  border-radius: 5px;
  text-align: center;
}

.tab_selected p {
  color: white !important;
  font-weight: bold;
  font-size: 15px;
}

.tab_static {
  padding: 6px;
  /* padding-left: 15px;
  text-align: center; */

}

.active_sidebar {
  background-color: var(--primary-bg-color-light);
  color: white;
}

.active_sidebar span {
  color: white !important;
}

.ant-drawer-body {
  overflow: hidden !important;
}

/* .ant-modal-close-x {
  display: contents;
  width: 56px;
  height: 56px;
  font-size: 24px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
} */

@media only screen and (max-width: 832px) {
  .ant-steps {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 832px) {
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0px !important;
    margin-top: 10px !important;
   
  }
}


p .btn {
  text-decoration: none !important;
}

p .btn:hover {
  text-decoration: underline !important;
}


.newAllModalCss .ant-modal-content{
  box-shadow : unset !important;
}
.ant-steps-item-finish .ant-steps-item-icon{
  color: var(--green);
  border: 2px solid var(--green);
  background-color: var(--lightGreen1);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
  color: var(--green);
}
/* .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  background-color: var(--green)!important;

} */
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--green)!important; 
  height: 2px;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::before{
  height: 2px;
}

.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 16px;
  background: #fff;
  border-radius: 5px;
  /* border: 1px solid #CCCCCC; */
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  text-align: left;
  background: var(--lightgray1);
  border-bottom: 1px solid var(--primary-color);
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: var(--primary-color);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  height: 45px !important;
    position: relative;
    background-color: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}
.ant-select-focused:not(.ant-select-disabled) .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: var(--primary-color) !important;
  border-right-width: 1px !important;
  outline : 0 !important;
  box-shadow: 0 0 0 2px  var(--primary-color)!important;

}

.ant-upload.ant-upload-drag{
  position: relative;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  background: #FFFAFA;
  border: 1px solid #CCCCCC !important;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
  text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-tab:hover {
    color: var(--primary-color);
    text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: var(--primary-color);
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: var(--primary-color);
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-spinning{
  color: var(--primary-color);
  font-weight: 600;
}
.ant-btn-primary {
  color: #fff;
  border-color: var(--primary-bg-color-light);
  background:var(--primary-bg-color-light);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:active {
  color: #fff;
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-bg-color-light);
  border-right-width: 1px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {

  border-color: var(--primary-bg-color-light) !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow:  0 0 0 2px rgba(180,118,211,0.2)  !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid var(--primary-color) !important;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.btn-primary{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary.active:not(:disabled):not(.disabled), .btn-primary:active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff;
}
.btn{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff;
}

.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(5, 5, 5, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  overflow: hidden;
}

.uploadDragger .ant-upload.ant-upload-drag {
      height: 140px !important; 
}

.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 10px;
  font-size: 16px;
  background: transparent;
  outline: none;
  cursor: pointer;
  
  /* border-top: 1px solid var(--lightgray1);
  border-left: 1px solid var(--lightgray1);
  border-right: 1px solid var(--lightgray1); */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-bottom: 1px;
}
.ant-tabs-tab-active {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 10px;
  font-size: 16px;
  background: transparent;
  outline: none;
  cursor: pointer;
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #fff !important;
  pointer-events: none;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid var(--primary-color);
  content: '';
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0 !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  color: #a11b1b !important;
  
 
}
/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8958A2;
  color: var(--primary-color);
  text-shadow: 0 0 0.25px currentColor;
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
} */

input {
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  padding: 10px;
  width: 100%;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}
.ant-input:focus, .ant-input-focused {
  border-color: var(--primary-color);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(180,118,211,0.2)
}
.ant-input:hover{
  border-color: var(--primary-color);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(180,118,211,0.2)
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--primary-color) !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  color: var(--green);
  border: 2px solid var(--green);
  background-color: var(--lightGreen1);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #000;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
     
  border-color: var(--primary-light-border-color) !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: var(--primary-light-border-color) !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color:var(--primary-light-border-color) !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--green);
}
.ant-card-bordered {
  border: 0px solid #f0f0f0;
}
.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: none;
  border-radius: 5px;
  background-color: #ebebec;
}
.table thead {
  background-color: #f8f9fa;
  border-bottom: 1px solid var(--primary-color) !important;
}
.table{
  border: 1px solid #c0c0c0;
  border-radius: 5px !important;
  border-collapse:separate !important;
} 



/* .table tr:first-child>td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none !important;
} */


.ant-picker {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 8px 15px ;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover, .ant-picker-focused {
  border-color: var(--primary-color);
  border-right-width: 1px !important;
}
.ant-picker-focused {
  border-color: var(--primary-color);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(137,88,162, 0.2);
}

.ant-picker-suffix {
    align-self: center;
    margin-left: 4px;
    color: rgb(137 88 162 / 100%);
    line-height: 1;
    pointer-events: none;
    border-left: 1px solid var();
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px !important;
  position: relative;
  background-color: #fff;
  border: 1px solid #8958A2;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  align-items: center;
}

.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 10px !important;
}
.ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 10px 10px !important;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 10px 10px;
}
.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 10px 10px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  font-size: 16px;
  font-weight: 600;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px !important;
}
.ant-form label {
  font-size: 16px !important;
}

.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 16px !important;
  line-height: 24px;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px important;  
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-table.ant-table-small {
  font-size: 16px !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 1px;
 
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #cccccc;
}

.table2{
  border: 1px solid #c0c0c0;
  border-collapse: separate;
  border-radius: 5px;
  width: 100%;
} 

.table2 td {
  padding: 12px !important;
  border-bottom: 1px solid #c0c0c0 !important;
}
.table2 tr:last-child td{
  border-bottom: none !important;
}

