.contentSelect{
  height: 70vh;
}
.productImg{
  /* width: 160px;
  height: 150px !important; */
  object-fit: cover;
  width: 100%;
}
.PlusImg{
  width: 120px;
  object-fit: cover;
  width: 100%;
}
.ant-radio-checked .ant-radio-inner{
    border-color: #1a6ab1 !important ;
  }
  .ant-radio-wrapper{
    align-items: center !important;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #1a6ab1;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: #1a6ab1 ;
  }
  .doctor-widget{
    /* align-items: center !important; */
    align-items: center !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    text-align: left !important;
  }
  
  .card-body{
    padding: 1.3rem !important;
  }
  .card_static{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100% !important ;
    margin-bottom: 1rem !important;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
    background-color: #e6e6e6 !important ;
    border: 5px solid white !important;
    padding: 25px;
  }
  .card_selected{
    border: 5px solid #1a6ab1 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100% !important ;
    margin-bottom: 1rem !important;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
    background-color: #e6e6e6 !important ;
    padding: 25px;
  }
  .div_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end !important;
    padding-right: 20px !important;
    /* padding-top: 10px; */
    padding-bottom: 20px;
    /* margin-top: 35px; */
}
.btn{
    background-color: #1a6ab1 !important;
    border: 1px solid #1a6ab1 !important;
    width: 200px;
    color: white !important;
    height: 45px !important;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 ;
  margin-bottom: 0;
}
.box{
  margin-top: 20px;
  background-color: #F9FAFD ;
  height: 85px;
  width: 100%;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
 
}
.pageInfo{
  color: #0071DC;
  font-size: 25px;
  font-weight: 700;
  margin: 0px;
}
.h5New{
  color: #000 !important;
  font-size: 15px;
  margin: 0 0 8px;
}
.radioCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .divPayment{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0px 35px 0;
} */
.lblPayment{
  /* color: gray; */
}



.div_inner_flex{
  width: 28%;
  margin-right: 10px;
  margin-left: 20px;
  cursor: pointer;
  padding-top: 15px;
  /* padding-bottom: 150px; */
}
.div_inner_flex_Dynamic{
  /* width: 30%; */
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}


.box{
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px !important;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 80px;
  width: 100%;
  color: white;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.h5New{
  color: white !important;
}
.pageInfo{
  /* padding-top: 10px; */
  /* color: #0071DC !important; */
  color: #ffffff !important;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}
/* .box{
  height: 100% !important;
} */

@media only screen and (max-width: 600px) {
      .div_flex{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

    .div_inner_flex{
      width: 90% !important;
      margin-right: 10px;
      margin-left: 10px;
      padding-bottom: 50px !important;
    }
}
@media only screen and (max-width: 300px) {
      .div_flex{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

    .div_inner_flex{
      width: 90% !important;
      margin-right: 10px;
      margin-left: 10px;
      padding-bottom: 50px !important;
    }
}
@media only screen and (max-width: 1000px) {
      .div_flex{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 100px;
      }

    /* .div_inner_flex{
      width: 40%;
      margin-right: 10px;
      margin-left: 10px;
      padding-bottom: 20px;
    } */
    /* .div_inner_flex_Dynamic{
      width: 40%;
      margin-right: 10px;
      margin-left: 10px;
      padding-bottom: 20px;
    } */
}

/* .ant-steps-item-icon, .ant-steps-item-content {
  display: inline-block !important;
  vertical-align: baseline !important;
} */